import React, { useState, useEffect } from 'react'
import styled from "@emotion/styled"
import { Api, Constants } from 'scg.common-library'
import {useTranslation} from "react-i18next"
import { BarChart } from '@mui/x-charts/BarChart'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Loading } from '../general/form/Loading'
import _ from 'lodash'
import './statistics.css'

const CategoryButton = styled(ToggleButton)`
    line-height: 1.2;
    border: 0;
    letter-spacing: 0;
    padding: .6em 1em;
    text-transform: inherit;
    font-family: 'Segoe UI', Open Sans, sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: var(--accent-background);
    border-radius: 14px !important;
    &.Mui-selected,
    &.Mui-selected:hover{
        color: var(--text-color);
        background-color: var(--accent-background);
    },
`;


const Challenge = ({ stats }) => {
    const {t} = useTranslation()
    return (
        <div className="block-stats block-challenge">
            <div className="inner-block">
                <img alt="challenge" src={process.env.PUBLIC_URL + '/img/statistics/icon-challenge.svg'} style={{maxWidth: '4vw'}}/>
                <h2 className="title-stats">{t("societies.statistics.challengeTitle")}</h2>
                <span className="number">{stats?.count ? stats?.count : 0}</span>
                <p className="completed">{t("societies.statistics.completed")}</p>
                <div className="challenge-percent">
                    <span className="percent">{`${stats?.successPercent ? stats?.successPercent : 0} %`}</span>
                    <span className="progress"><span className="pro-percent" style={{width: `${stats?.successPercent}%`}}></span></span>
                    <span className="rate">{t("societies.statistics.rate")}</span>
                </div>
            </div>
        </div>
    )
}

const Quiz = ({stats}) => {
    const {t} = useTranslation()
    return (
        <div className="block-stats block-quiz">
            <div className="inner-block">
                <img alt="challenge" src={process.env.PUBLIC_URL + '/img/statistics/icon-quizz.svg'} style={{ maxWidth : '3vw' }} />
                <h2 className="title-stats">{t("societies.statistics.quizTitle")}</h2>
                <span className="number">{ stats?.count ? stats?.count : 0 }</span>
                <p className="completed">{t("societies.statistics.completed")}</p>
                <div className="challenge-percent">
                    <span className="percent">{`${stats?.successPercent ? stats?.successPercent : 0} %`}</span>
                    <span className="progress"><span className="pro-percent" style={{width: `${stats?.successPercent}%`}}></span></span>
                    <span className="rate">{t("societies.statistics.rate")}</span>
                </div>
            </div>
        </div>
    )
}

const Connexion = ({ stats }) => {
    const {t} = useTranslation()
    return (
        <div className="block-stats block-connexion">
            <div className="inner-block">
                <h2 className="title-stats">{t("societies.statistics.nbrConnexion")}</h2>
                <div className="connexion-stats">
                    <div className="stats-value">
                        <span className="number">{stats?.count ? stats.count : 0 }</span>
                    </div>
                    <div className="stats-detail">
                        <span className="current-series">{t('societies.statistics.seriesCurrent')} <strong>{  stats?.successiveConnexion ? stats.successiveConnexion : 0 }</strong></span>
                        <span className="best-series">{t('societies.statistics.seriesBest')} <strong>{ stats?.bestSuccessiveConnexion ? stats.bestSuccessiveConnexion : 0 }</strong></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CategoryStatistics = ({ category, value=0, questions=0 }) => {
    const {t} = useTranslation()
    return (
        <div className="cat-statistic">
            <p className="cat-name">{category}</p>
            <div className="cat-stat-value">
                <img src={process.env.PUBLIC_URL + '/img/statistics/arrow.svg'} alt=""/>
                <span className="cat-stat-percent">{`${value} %`} <span>{t('societies.statistics.seriesSuccess')}</span></span>
            </div>
            <div className="cat-response">
                <span className="resp-label">{t('societies.statistics.response')}</span>
                <span className="resp-value">{questions}</span>
            </div>
        </div>
    )
}

const Categories = ({ best, worse }) => {
    const [view, setView] = useState('best')
    const {t} = useTranslation()
    return (
        <div className="block-stats block-category">
            <div className="inner-block">
                <div className="cat-head">
                    <h2 className="title-stats">{t('societies.statistics.categoryTitle')}</h2>
                    <ToggleButtonGroup
                        value={view}
                        exclusive={true}
                    >
                        <CategoryButton value='best' onChange={() => setView('best')}>{t('societies.statistics.categoryBest')}</CategoryButton>
                        <CategoryButton value='worse' onChange={() => setView('worse')}>{t('societies.statistics.categoryWorse')}</CategoryButton>
                    </ToggleButtonGroup>
                </div>
                { view === 'best' && <CategoryStatistics category={best?.name} value={best?.successPercent} questions={best?.questionCount} /> }
                { view === 'worse' && <CategoryStatistics category={worse?.name} value={worse?.successPercent} questions={worse?.questionCount} /> }
            </div>
        </div>
    )
}

const renderBarChart = (data) => {
    const {t} = useTranslation()
    const width = ( window.innerWidth * 50 ) / 100
    const height = ( window.innerHeight * 41 ) / 100
    if( !data.length ){
        return (
            <div className='bar-chart-no-data'>
                <p>{t('societies.statistics.noData')}</p>
            </div>
        )
    }
    return (
        <BarChart
            dataset={data}
                xAxis={[
                    {
                        dataKey: 'category',
                        id: 'barCategories',
                        scaleType: 'band',
                        labelStyle: {
                            fontSize: 16

                        },
                        tickLabelInterval: () => true,
                        valueFormatter: (category, context) =>
                            context.location === 'tick'
                              ? _.truncate(category, { length: 18 } )
                              : category,
                    }
                ]}
                yAxis={[{
                    valueFormatter: (v) => v + '%',
                    labelStyle: {
                        fontSize: 16

                    },
                }]}
                series={[
                    {
                        dataKey: 'percent',
                        color: "#61DAFB",
                        valueFormatter: (v) => v + '%',
                    }
                ]}
                grid={{ horizontal: true }}
                borderRadius={20}
                width={width}
                height={height}
            />
    )
}

const CategoriesChart = ({ stats }) => {
    const {t} = useTranslation()
    let dataset = []
    if(stats){
        stats.forEach( stat => {
            dataset.push({ 'category' : stat.name, 'percent' : stat.successPercent })
        } )
    }
    return (
        <div className="block-stats block-chart">
            <div className="top-chart">
                <h2 className="chart-title">{t("societies.statistics.chartTitle")}</h2>
                <span className="chart-success">{t("societies.statistics.chartSuccess")}</span>
            </div>
            { renderBarChart(dataset) }
        </div>
    )
}

const UserStatistics = ({ stats }) => {
    return (
        <div className="inner-statistics">
            <div className="left-column">
                <Challenge stats={stats?.challenge}/>
                <Quiz stats={stats?.quiz}/>
            </div>
            <div className="right-column">
                <div className="top-right">
                    <Connexion stats={stats?.connexion}/>
                    <Categories best={stats?.bestCategory} worse={stats?.worstCategory} />
                </div>
                <div className="bottom-right">
                    <CategoriesChart stats={stats?.categories} />
                </div>
            </div>
        </div>
    )
}

const Statistics = ({ userId }) => {

    const [stats, seStats] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect( () => {
        Api.user.getStatsByIdUser(userId)
            .then( response => {
                if( response.status === Constants.HTTP_OK ){
                    seStats(response.data)
                }
            } )
            .finally( () => setIsLoading(false) )
    }, [] )

    return (
        <div className="statistics-container">
            { isLoading && <Loading /> }
            <UserStatistics stats={stats} />
        </div>
    )
}

export default Statistics