import {useState, useEffect} from "react"
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Checkbox from '@mui/material/Checkbox'
import {Api, Constants} from 'scg.common-library'
import {Loading} from "../../general/form/Loading"
import {useTranslation} from 'react-i18next'
import './truefalseAnswer.css'

async function getAnswer(questionId, callback, callbackOnError) {
    if (questionId !== null) {
        const response = await Api.truefalseAnswer.getTruefalseAnswersForQuestion(questionId)
        if (response?.status === Constants.HTTP_OK || response.status === Constants.HTTP_CREATED) {
            callback(response.data)
        } else {
            callbackOnError()
        }
    }
}

function TruefalseAnswer({
    questionId,
    initTruefalseAnswer,
    refreshCountListe,
    callbackOtherRefresh,
    allTruefalseLabel,
    handleChangeQuestionTruefalseAnswer
}) {
    const {t} = useTranslation()
    const [listAnswer, setListAnswer] = useState([])
    const [selectValue, setSelectValue] = useState(null)
    const [loading, setLoading] = useState(false)
    const disableLoading = () => {
        const list = []
        //let defaultTrue = true
        let defaultSelectValue = null
        let index = 0
        for (let truefalseLabel of allTruefalseLabel) {
            list.push({
                id: null,
                correct: truefalseLabel.value,
                truefalseLabelId: truefalseLabel.id,
                truefalseLabel: truefalseLabel.label,
                question: questionId,
                new: true,
                uniqId: index
            })
            //if (defaultTrue) {
            if (truefalseLabel.value) {
                defaultSelectValue = index
                //defaultTrue = false
            }
            index++
        }
        handleChangeQuestionTruefalseAnswer(list)
        setListAnswer(list)
                setSelectValue(defaultSelectValue)
        setLoading(false)
    }
    const handleGetAnswer = (data) => {
                const list = []
        let index = 0
        let defaultSelectValue = null
        for (let truefalseAnswer of data) {
            list.push({
                id: truefalseAnswer.id,
                correct: truefalseAnswer.correct,
                truefalseLabelId: truefalseAnswer.truefalseLabel.id,
                truefalseLabel: truefalseAnswer.truefalseLabel.label,
                question: questionId,
                new: false,
                uniqId: index + (truefalseAnswer.id*3)
            })
            if (truefalseAnswer.correct) {
                defaultSelectValue = index + (truefalseAnswer.id*3)
            }
            index++
        }
        setListAnswer(list)
        handleChangeQuestionTruefalseAnswer(list)
        setSelectValue(defaultSelectValue)
        setLoading(false)
            }

    const handleChangeSelectValue = (value) => {
        const list = []
        for (let truefalseAnswer of listAnswer) {
            list.push({
                id: truefalseAnswer.id,
                correct: value === truefalseAnswer.uniqId,
                truefalseLabelId: truefalseAnswer.truefalseLabelId,
                truefalseLabel: truefalseAnswer.truefalseLabel,
                question: truefalseAnswer.question,
                new: truefalseAnswer.new,
                uniqId: truefalseAnswer.uniqId
            })
        }
        setSelectValue(value)
        handleChangeQuestionTruefalseAnswer(list)
        setListAnswer(list)
            }

    useEffect(() => {
        if (refreshCountListe > 0) {
            setLoading(true)
            if (refreshCountListe === listAnswer.length) {
                getAnswer(questionId, handleGetAnswer, disableLoading)
                callbackOtherRefresh()
            }
        }
    }, [refreshCountListe])

    useEffect(() => {
        if (questionId !== null) {
            setLoading(true)
            getAnswer(questionId, handleGetAnswer, disableLoading)
        } else {
            disableLoading()
        }
    }, [initTruefalseAnswer])

    return <div className='container-mcqanswer'>
        {loading && <Loading />}
        <Grid container>
            <Grid item xs={12}>
                <span>{t('quiz.pages.questions.trueFalse.instruction')}</span>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
                <Grid container>
                    {listAnswer.map(el => <BlockResponse
                        truefalseAnswer={el} key={el.uniqId}
                        handleChangeSelectValue={handleChangeSelectValue}
                        selectValue={selectValue}
                    />)}
                </Grid>
            </Grid>
            <Grid item xs={4}></Grid>
        </Grid>
    </div>
}

function BlockResponse({truefalseAnswer, handleChangeSelectValue, selectValue}) {
    const {t} = useTranslation()
    const handleIsTrueAnswer = () => {
        handleChangeSelectValue(truefalseAnswer.uniqId)
    }
    
    return <Grid item xs={6} className='container-block-response text-align-center'>
        <Paper component='form' variant="outlined"
               sx={{display: 'flex', alignItems: 'center'}}>
            <Checkbox checked={truefalseAnswer.uniqId === selectValue} onChange={handleIsTrueAnswer} />
            <InputBase
                    sx={{ml: 1, flex: 1}}
                    value={t(truefalseAnswer.truefalseLabel)}
                    placeholder={t(truefalseAnswer.truefalseLabel)}
                    type='text'
            />
        </Paper>
    </Grid>
}

export default TruefalseAnswer