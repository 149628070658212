import React, {useEffect, useState} from "react"
import Grid from '@mui/material/Grid'
import {Loading} from "../../general/form/Loading"
import {useTranslation} from 'react-i18next'
import './gapsAnswer.css'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TextField from "@mui/material/TextField";
import {Api, Constants} from "scg.common-library";
import {MAX_GAPS_ANSWERS} from "../../../services/Constants";


function extractFreePosition(gapsAnswers){
    for (let i = 1; i <= MAX_GAPS_ANSWERS; i++) {
        const checkTest = gapsAnswers.filter(item => item.position === i)

        if (checkTest.length === 0) {
            return i
        }
    }
    return null
}

async function getAnswer(questionId, callback, callbackOnError) {
    if (questionId !== null) {
        const response = await Api.gapsAnswer.getGapsAnswersForQuestion(questionId)
        if (response?.status === Constants.HTTP_OK || response.status === Constants.HTTP_CREATED) {
            callback(response.data)
        } else {
            callbackOnError()
        }
    }
}

function GapsAnswer({
    questionId,
    initGapsAnswer,
    callbackUpdateListe,
    refreshCountListe,
    callbackOtherRefresh,
    injectPositionGapsAnswer
}) {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [listAnswer, setListAnswer] = useState([])
    const [countAnswer, setCountAnswer] = useState(0)

    const disableLoading = () => {
        setLoading(false)
    }

    const callbackChangeValue = (gapsAnswer) => {
        setListAnswer(prevList => prevList.map(el => el.uniqId === gapsAnswer.uniqId ? gapsAnswer : el))
    }

    const handleAddAnswer = () => {
        setCountAnswer(countAnswer + 1)
        setListAnswer((prevList) => {
            const position = extractFreePosition(prevList)
            if (position === null) {
                return prevList
            } else {
                injectPositionGapsAnswer(position)
                prevList.push({
                    id: null,
                    text: "",
                    position: position,
                    question: questionId,
                    new: true,
                    uniqId: questionId + "-" + position
                })
                return prevList
            }
        })
    }

    const handleGetAnswer = (data) => {
        const list = []
        for (let gapsAnswer of data) {
            list.push({
                id: gapsAnswer.id,
                text: gapsAnswer.text,
                position: gapsAnswer.position,
                question: gapsAnswer.question,
                new: false,
                uniqId: gapsAnswer.id + "-" + gapsAnswer.position
            })
        }
        setListAnswer(list)
        setCountAnswer(list.length)
        setLoading(false)
    }

    const initResponse = () => {
        const minGapsAnswer = 3
        setCountAnswer(minGapsAnswer)
        const initResponseArray = []
        for (let i = 1; i <= minGapsAnswer; i++) {
            const position = extractFreePosition(initResponseArray)
            if (position !== null) {
                initResponseArray.push({
                    id: null,
                    text: "",
                    position: position,
                    question: questionId,
                    new: true,
                    uniqId: questionId + "-" + position
                })
            }
        }
        setListAnswer(initResponseArray)
    }

    useEffect(() => {
        if (refreshCountListe > 0) {
            setLoading(true)
            console.log(listAnswer.length, refreshCountListe)
            if (refreshCountListe === listAnswer.length || (refreshCountListe % listAnswer.length) === 0) {
                getAnswer(questionId, handleGetAnswer, disableLoading)
                callbackOtherRefresh()
            }
        }
    }, [refreshCountListe])

    useEffect(() => {
        if (questionId !== null) {
            setLoading(true)
            getAnswer(questionId, handleGetAnswer, disableLoading)
        } else {
            initResponse()
        }
    }, [initGapsAnswer])

    useEffect(() => {callbackUpdateListe(listAnswer)}, [listAnswer])

    return <div className='container-gapsanswer'>
        {loading && <Loading />}
        <Grid container>
            <Grid item xs={12}>
                <span className="tag-gaps"><b>{t('quiz.pages.questions.gapsAnswer.tag')}</b></span>
                <button className={`btn default ${countAnswer > 7 ? "disabled" : ""}`}
                        title={t('quiz.pages.questions.gapsAnswer.addTag')}
                        onClick={() => handleAddAnswer()}>
                    {t('quiz.pages.questions.gapsAnswer.addTag')}
                </button>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={6}>
                        <div>{t('quiz.pages.questions.gapsAnswer.instructionTag')}</div>
                    </Grid>
                    <Grid item xs={4} style={{textAlign: "right"}}>
                        <div style={{display: "flex"}}>
                            <div style={{display: "flex", alignItems: "center"}}><QuestionMarkIcon fontSize="large" /></div>
                            <div>{t('quiz.pages.questions.gapsAnswer.deleteTag')}</div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container style={{paddingTop: 15, overflow: "overlay", height: 260}}>
            <Grid item xs={12}>
                {listAnswer.map(el => <BlockGapsResponse
                    gapsAnswer={el} key={el.uniqId}
                    callbackChangeValue={callbackChangeValue}
                    injectPositionGapsAnswer={injectPositionGapsAnswer}
                />)}
            </Grid>
        </Grid>
    </div>
}

function BlockGapsResponse({gapsAnswer, callbackChangeValue, injectPositionGapsAnswer}) {
    const {t} = useTranslation()
    const [value, setValue] = useState(gapsAnswer.text)
    useEffect(() => {
        callbackChangeValue({
            id: gapsAnswer.id,
            text: value,
            position: gapsAnswer.position,
            question: gapsAnswer.question,
            new: gapsAnswer.new,
            uniqId: gapsAnswer.uniqId
        })
    }, [value])
    const handleValueAnswer = e => {
        setValue(e.target.value)
    }

    return <Grid container style={{paddingTop: 8}}>
        <Grid item xs={2}>
            <div className="flex" style={{height: "100%"}}>
                <div onClick={() => injectPositionGapsAnswer(gapsAnswer.position)}
                     style={{cursor: "pointer"}} className="flex"
                     title={t('quiz.pages.questions.gapsAnswer.choiceTitle')}
                ><span>{t('quiz.pages.questions.gapsAnswer.choice')} [[{gapsAnswer.position}]] : </span></div>
            </div>
        </Grid>
        <Grid item xs={9}>
            <TextField
                fullWidth
                value={value}
                onChange={handleValueAnswer}
                label={t('quiz.pages.questions.gapsAnswer.placeholder')}
                variant="outlined" />
        </Grid>
    </Grid>
}

export default GapsAnswer
