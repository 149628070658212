import React, { useState, useEffect } from 'react'
import {Constants } from 'scg.common-library'
import { useTranslation } from 'react-i18next'
import HtmlStructure from '../general/HtmlStructure'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Loading } from '../general/form/Loading'

import './changelog.css'

export default function ChangelogPage() {
    const { t } = useTranslation()
    document.title = `${t('changelog.documentTitle')} - ` + Constants.DOCUMENT_TITLE_BACKOFFICE

    return (
        <HtmlStructure menuName={t('changelog.documentTitle')} sectionClassName="changelog">
            <Changelog />
        </HtmlStructure>
    );
}

export const Changelog = () => {
    
    const { t } = useTranslation()
    const [markdown, setMarkdown] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    useEffect( () => {
        fetch( `${process.env.PUBLIC_URL}/CHANGELOG.md` )
            .then( response => response.text() )
            .then ( data => setMarkdown(data) )
            .finally( () => setIsLoading(false) )
    }, [] )

    return (
        <article className='changelog'>
            { isLoading && <Loading /> }
            <section className='bo-data-title'>
                <h3>{t('changelog.title')}</h3>
            </section>
            <section className='bo-data-table card'>
                <div className='markdown-body'>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
                </div>
            </section>
        </article>
        
    )

}