import {Component} from 'react'
import {Constants, Roles, Session} from 'scg.common-library'
import {Link, NavLink} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {routes as Routing} from '../../../services/RoutesHelper'
import {withTranslation} from 'react-i18next'
import i18next from 'i18next'

import './menu.css'

const Menu = withTranslation()(
    class extends Component {
        static defaultProps = {}

        constructor(props) {
            super(props)

            this.sessionUser = Session.getSessionUser()

            this.state = {
                isAuthenticated: Session.isLoggedIn(),
                user: this.sessionUser
            }
            if (this.state.user?.roles === undefined) Session.handleLogout()
        }

        render() {
            const {t} = this.props

            return (
                <aside className='menu'>
                    <div className='menu-title'>
                        <NavLink to={Routing.app_home} title={Constants.DOCUMENT_TITLE_BACKOFFICE} role='navigation'>
                            <img src='/favicons/android-chrome-192x192.png' alt={Constants.DOCUMENT_TITLE_BACKOFFICE}/>
                        </NavLink>
                    </div>

                    <nav className='menu-nav' role='menu'>
                        {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) && DashboardMenu(this.props.nameMenu)}
                        {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) && UserMenu(this.props.nameMenu)}
                        {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_SUPER_ADMIN) && SocietyMenu(this.props.nameMenu)}
                        {this.state.user?.roles[0] === Constants.ROLE_ADMIN && SocietyDetailsMenu(this.props.nameMenu)}
                        {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_SUPER_ADMIN) && QuizMenu(this.props.nameMenu)}
                        {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_SUPER_ADMIN) && ChallengeMenu(this.props.nameMenu)}
                        {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_SUPER_ADMIN) && ReportMenu(this.props.nameMenu)}
                        {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_ADMIN) && StatsMenu(this.props.nameMenu)}
                        {Roles.isGranted(this.state.user?.roles[0], Constants.ROLE_SUPER_ADMIN) && SystemMenu(this.props.nameMenu)}
                    </nav>
                    <div className='menu-footer'>
                        <div className='menu-user' title={`${t('general.menu.footer.title')} ${this.state.user?.pseudo}`}>
                            <span className='menu-user-name'>
                                <FontAwesomeIcon icon='fas fa-circle-user' />{' ' + this.state.user?.pseudo}
                            </span>
                            <span className='menu-user-role'>
                                {Roles.getRoleString(this.state.user?.roles[0])}
                            </span>
                            <span className='menu-user-society'>
                                {this.state.user?.society?.name}
                            </span>
                            <Link to={Routing.app_logout} title={t('session.disconnect')}
                                  className='menu-user-logout'>
                                <FontAwesomeIcon icon='fas fa-arrow-right-from-bracket' />
                            </Link>
                        </div>
                        <div className='menu-version'>
                            {t('general.menu.footer.version')} {process.env.REACT_APP_VERSION}
                        </div>
                    </div>
                </aside>
            )
        }
    }
)

function DashboardMenu(nameMenu) {
    return generateMenuItem(
        Routing.bo_dashboard,
        'fas fa-tachometer-alt',
        i18next.t('general.menu.names.dashboard'),
        nameMenu === 'dashboard'
    )
}

function UserMenu(nameMenu) {
    return generateMenuItem(
        Routing.bo_users,
        'fas fa-users',
        i18next.t('general.menu.names.users'),
        nameMenu === 'users'
    )
}

function SocietyMenu(nameMenu) {
    return generateMenuItem(
        Routing.bo_society,
        'fas fa-building-user',
        i18next.t('general.menu.names.companies'),
        nameMenu === 'society'
    )
}

function SocietyDetailsMenu(nameMenu) {
    return generateMenuItem(
        Routing.bo_society_details,
        'fa-solid fa-industry',
        i18next.t('general.menu.names.company'),
        nameMenu === 'society_details'
    )
}

function QuizMenu(nameMenu) {
    return generateMenuItem(
        Routing.bo_question,
        'fas fa-clipboard-question',
        i18next.t('general.menu.names.quiz'),
        nameMenu === 'quiz'
    )
}

function ChallengeMenu(nameMenu) {
    return generateMenuItem(
        Routing.bo_challenge,
        'fas fa-list-check',
        i18next.t('general.menu.names.challenges'),
        nameMenu === 'challenge'
    )
}

function ReportMenu(nameMenu) {
    return generateMenuItem(
        Routing.bo_report,
        'fas fa-flag',
        i18next.t('general.menu.names.reports'),
        nameMenu === 'report'
    )
}

function StatsMenu(nameMenu) {
    return generateMenuItem(
        Routing.bo_society_stats,
        'fa-solid fa-chart-simple',
        i18next.t('general.menu.names.stats'),
        nameMenu === 'stats'
    )
}

function SystemMenu(nameMenu) {
    return generateMenuItem(
        Routing.bo_system,
        'fa-solid fa-wrench',
        i18next.t('general.menu.names.system'),
        nameMenu === 'system'
    )
}

function generateMenuItem(
    link,
    iconClassName,
    label,
    isActive = false,
    title = null
) {
    const activeClass = isActive ? 'active' : ''
    return (
        <NavLink
            to={link}
            title={title ?? label}
            className={`menu-item ${activeClass}`}
            role='menuitem'
        >
            <FontAwesomeIcon icon={iconClassName} className='menu-icon'/>
            <span className='menu-text'>{label}</span>
        </NavLink>
    )
}

export default Menu
