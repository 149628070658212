import React from 'react'
import {Constants, Roles, Session} from 'scg.common-library'
import {NavLink} from 'react-router-dom'
import {toast} from 'react-toastify'
import _ from 'lodash'
import {COOKIE_PER_PAGE_NUMBER, DEFAULT_PER_PAGE_NUMBER} from './Constants'
import i18next from 'i18next'
import moment from "moment";
import { 
    Tooltip
} from '@mui/material'
import { 
	TrendingUp as TrendingUpIcon,
	TrendingFlat as TrendinFlatIcon,
	TrendingDown as TrendingDownIcon
} from '@mui/icons-material'

/**
 *
 * @param length {number}
 * @return {string}
 */
function generateUUID(length = 5) {
    const uuid = crypto.randomUUID()
    return uuid.slice(uuid.length - length)
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max)
}

/**
 *
 * @param {string} message - The message to display
 * @param {('success'|'error'|'warning'|'info')} type - Type of toast to display
 * @param {?int} delay - Time in milliseconds after which the message will fade out
 */
function displayMessage(message, type = "success", delay = null) {
    delay = delay ?? 2000
    switch (type) {
        case 'success':
            toast.success(message, getToastOptions(delay))
            break
        case 'error':
            toast.error(message, getToastOptions(delay))
            break
        case 'warning':
            toast.warning(message, getToastOptions(delay))
            break
        default:
            toast.info(message, getToastOptions(delay))
            break
    }
}

function getToastOptions(
    delay = 2000,
    theme = 'colored',
    position = toast.POSITION.BOTTOM_RIGHT
) {
    return {
        position: position,
        autoClose: delay,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme
    }
}

function isValidResponse(responseObject) {
    let response = false
    if (
        responseObject.hasOwnProperty('data') &&
        typeof responseObject.data === 'object'
    ) {
        response = responseObject.data
        if (
            responseObject.data.hasOwnProperty('@type') &&
            responseObject.data['@type'] === 'hydra:Collection'
        ) {
            response = responseObject.data['hydra:member']
        }
    }
    return response
}

async function getDataTableLines(
    apiFunction,
    urlParams,
    stateParamName,
    formatFunction,
    page = 1,
    filters = null,
    callback = null
) {
    if (filters !== null) {
        urlParams = getFiltersUrlParams(filters, urlParams)
    }

    // call questions API
    const result = await apiFunction(page, urlParams)
    const resultObject = isValidResponse(result)

    if (resultObject) {
        let data = []
        for (const object of resultObject) {
            data.push(await formatFunction.bind(this)(object))
        }
        // Update table state with formatted rows
        this.setState({[stateParamName]: data, loading: false}, () => {
            if (callback !== null && callback instanceof Function) {
                callback()
            }
        })
    }
}

function getTablePerPageNumber() {
    const number = Session.getCookie(COOKIE_PER_PAGE_NUMBER)
    return number ?? DEFAULT_PER_PAGE_NUMBER
}

function setTablePerPageNumber(perPage) {
    Session.setCookie(COOKIE_PER_PAGE_NUMBER, perPage)
}

/**
 * Format a text link to an html div used for dataTable display
 *
 * @param text {string}
 * @param className {string}
 * @param onClickLocation {null|string}
 * @return {JSX.Element}
 */
function FormatLink(text, className = 'text-display', onClickLocation = null) {
    if (onClickLocation === null) {
        return (
            <span className={className} title={text}>
        {text}
      </span>
        )
    }

    return (
        <NavLink className={className} title={text} to={onClickLocation}>
            <span>{text}</span>
        </NavLink>
    )
}

/**
 * Format a text to an html div used for dataTable display
 *
 * @param text {string}
 * @param className {string}
 * @return {JSX.Element}
 */
function FormatText(text, className = 'text-display', children) {
    return (
        <div className={className} title={text}>
            <span>{text}</span>
            {children}
        </div>
    )
}

/**
 * Format the pseudo to an html div used for dataTable display
 *
 * @param text {string}
 * @param className {string}
 * @return {JSX.Element}
 */
function FormatUsername(pseudo, tag, title, className = 'text-display') {
    return (
        <div className={className} title={title}>
            <span>{pseudo}</span>
            {tag}
        </div>
    )
}

/**
 * Format an email to a html div used for dataTable display
 *
 * @param mail {string}
 * @param className {string}
 * @return {JSX.Element}
 */
function FormatMail(mail, className = '') {
    return <div className={className} title={`${i18next.t('services.helper.formatEmailTitle')} ${mail}`}>
      <span>
        <a href={`mailto:${mail}`}>{mail}</a>
      </span>
    </div>
}

/**
 * Format a phone number to a html div used for dataTable display
 *
 * @param phone {string}
 * @param className {string}
 * @return {JSX.Element}
 */
function FormatPhone(phone, className = '') {
    return <div className={className} title={`${i18next.t('services.helper.formatPhoneTitle')} ${phone}`}>
      <span>
        <a href={`tel:${phone}`}>{phone}</a>
      </span>
    </div>
}

/**
 * Format a text to an html div stylised as a badge used for dataTable display
 *
 * @param text {string}
 * @param state
 * @return {JSX.Element}
 */
function FormatBadge(text, state) {
    return <div title={text}>
        <span className={`badge ${state ? 'green' : 'red'}`}>{text}</span>
    </div>
}

/**
 * Format a questionType object to an html div used for dataTable display
 *
 * @param questionType
 * @return {JSX.Element}
 */
function FormatType(questionType) {
    const badgeColor = {
        [Constants.QUESTION_MCQ]: 'blue',
        [Constants.QUESTION_TRUEFALSE]: 'purple',
        [Constants.QUESTION_GAPSTEXT]: 'yellow'
    }

    return <div className='badge-display' title={questionType.name}>
      <span className={`badge ${badgeColor[questionType.name]}`}>
        <img
            src={`/img/questionTypes/${questionType.shortName}.png`}
            alt={`${questionType.shortName}`}
            height='20px'
            title={questionType.name}
        />{' '}
          {questionType.name}
      </span>
    </div>
}

/**
 * Format a Level object to an html div used for dataTable display
 *
 * @param level
 * @param withName
 * @return {JSX.Element}
 */
function FormatLevel(level, withName = true) {
    return <div className='level-display' title={level.name}>
      <span>
        <img
            src={`/img/levels/${level.shortName}.png`}
            alt={`${level.shortName}`}
            height='20px'
            title={level.name}
        />{' '}
          {withName ? level.name : ''}
      </span>
    </div>
}

/**
 * Format a Flag object to an html div used for dataTable display
 *
 * @param language
 * @return {JSX.Element}
 */
function FormatFlag(language) {
    return <div className='text-display' title={language.code}>
        <img
            src={`/img/flags/${language.code}.png`}
            alt={`${language.code} flag`}
            width='25px'
            title={language.name}
        />
    </div>
}

/**
 * Format a progress object to an html div used for dataTable display
 *
 * @param progress
 * @return {JSX.Element}
 */
function FormatProgression(progress) {
    const { t } = i18next
    let progressCls, progressContent, progressTitle
    switch( progress ){
        case 1:
            progressCls = 'blue'
            progressTitle = t('societyDetails.progression.up')
            progressContent = <TrendingUpIcon className='progress-icon' />
        break;
        case -1 : 
            progressCls = 'red'
            progressTitle = t('societyDetails.progression.down')
            progressContent = <TrendingDownIcon className='progress-icon' />
        break;
        case 0:
            progressCls = 'orange'
            progressTitle = t('societyDetails.progression.default')
            progressContent = <TrendinFlatIcon className='progress-icon' />
        break;
        default : 
            progressCls = 'silver'
            progressContent = t('societyDetails.progression.unknown')
            progressTitle = t('societyDetails.progression.unknown')
            
    }
    return (
        <Tooltip placement='top' title={progressTitle}>
            <span className={`badge ${progressCls} progress-babge`}>
                { progressContent }
            </span>
        </Tooltip>
    )
}
/**
 * Check and format filter object content and put the result in urlParams object
 *
 * @param filters {object}
 * @param urlParams {object}
 * @return {object}
 */
function getFiltersUrlParams(filters, urlParams = {}) {
    if (filters.hasOwnProperty('questionFilterText')) {
        urlParams['question_filterText'] = filters.questionFilterText
    }
    if (filters.hasOwnProperty('userFilterText')) {
        urlParams['user_filterText'] = filters.userFilterText
    }
    if (filters.hasOwnProperty('filterText')) {
        urlParams['filterText'] = filters.filterText
    }
    if (filters.hasOwnProperty('subscribed')) {
        urlParams['subscribed'] = filters.subscribed
    }
    if (filters.hasOwnProperty('active')) {
        urlParams['active'] = filters.active
    }
    if (filters.hasOwnProperty('banned')) {
        urlParams['banned'] = filters.banned
    }
    if (filters.hasOwnProperty('role')) {
        urlParams['role'] = filters.role
    }
    if (filters.hasOwnProperty('questionTypes')) {
        urlParams['questionTypes'] = _.join(filters.questionTypes, ',')
    }
    if (filters.hasOwnProperty('categories')) {
        urlParams['categories'] = _.join(filters.categories, ',')
    }
    if (filters.hasOwnProperty('levels')) {
        urlParams['levels'] = _.join(filters.levels, ',')
    }
    if (filters.hasOwnProperty('languages')) {
        urlParams['languages'] = _.join(filters.languages, ',')
    }
    if (filters.hasOwnProperty('informationSheetFilterText')) {
        urlParams['information_sheet_text'] = filters.informationSheetFilterText
    }
    if (filters.hasOwnProperty('questionReportFilterStatus')) {
        urlParams['question_report_status'] = _.join(filters.questionReportFilterStatus, ',')
    }
    return urlParams
}

function isUserSuperAdmin() {
    return Roles.isGranted(
        Session.getSessionUser().roles[0],
        Constants.ROLE_SUPER_ADMIN
    )
}

function orderObjectByKeys(value) {
    return Object.keys(value).sort().reduce((obj, key) => {
        obj[key] = value[key]
        return obj
    }, {})
}

/**
 * Check licence of organisation
 *
 * @return boolean
 */
function isActivateLicence() {
    const user = Session.getSessionUser()
    let response = true
    if (user.hasOwnProperty("society")) {
        if (user.roles.includes(Constants.ROLE_SUPER_ADMIN)) {
            return response
        }
        if (moment() > moment(user.society.subscriptionExpirationDate)) {
            response = false
        }
    }

    return response
}

/**
 * Import moment locale dynamically
 *
 * @param {string} userLanguage
 * @return void
 */
function importLocale(userLanguage){
    let locale = 'fr'
    switch (userLanguage){
        case 'en':
            locale = 'en-gb'
            break
        case 'zh':
            locale = 'zh-cn'
            break
        case 'jp':
            locale = 'ja'
            break
        default:
            locale = 'fr'
    }
    import(`moment/locale/${locale}`)
    moment.locale(locale)
}

/**
 * Format date by user locale
 *
 * @param {string} entryDate
 * @param {object} i18n
 * @return string
 */
function formatDateByUserLocale(entryDate, i18n = null){
    let userLanguage = 'en'
    if( i18n && i18n.hasOwnProperty('resolvedLanguage') ){
        userLanguage = i18n.resolvedLanguage
        importLocale(userLanguage)
    }
    let date = moment.utc(entryDate).locale(userLanguage)
    return date.format('LLL').replace(':', 'h')
}

const exportedFunctions = {
    generateUUID,
    displayMessage,
    getToastOptions,
    isValidResponse,
    getDataTableLines,
    getFiltersUrlParams,
    FormatFlag,
    FormatLink,
    FormatText,
    FormatUsername,
    FormatMail,
    FormatPhone,
    FormatBadge,
    FormatLevel,
    FormatType,
    FormatProgression,
    isUserSuperAdmin,
    getTablePerPageNumber,
    setTablePerPageNumber,
    orderObjectByKeys,
    getRandomInt,
    isActivateLicence,
    formatDateByUserLocale,
    importLocale
}

export default exportedFunctions
