import { useEffect, useState } from 'react'
import ReactSwitch from 'react-switch'
import Helper from '../../../services/Helper'
import { useTranslation } from 'react-i18next'

/**
 * Return a switch component that update the question active state (question object passed in props)
 *
 * @param props {object}
 * @return {JSX.Element}
 */
export default function ActiveSwitch(props) {
    const { t } = useTranslation()

    const [active, setActive] = useState(props.objectActive)

    useEffect(() => {
        setActive(props.objectActive)
    }, [props])

    const handleChange = (activeState) => {
        setActive(activeState)
        if (props.onChange !== undefined) {
            props.onChange(activeState)
        }
    }

    if (props.apiFunction === undefined) {
        return (
            <ReactSwitch
                checked={active}
                className={props.className}
                id={`${props.idPrefix}-${props.objectId}`}
                onColor='#99e7fc'
                offColor='#fc9999'
                onChange={handleChange}
            />
        )
    }

    function callApiFunction(activeState){
        props.apiFunction(props.objectId, { active: activeState }).then(response => {
            setActive(activeState)
            if (response?.status === 200) {
                Helper.displayMessage(t('general.form.activeSwitch.success'))
            } else {
                Helper.displayMessage(t('general.form.activeSwitch.error'), 'error')
            }

            if (props.onChange !== undefined) {
                props.onChange(activeState)
            }
        })
    }

    const handleActiveSwitchChange = (activeState) => {
        if (props.withConfirmation) {
            setActive(activeState)
            return
        }
        //s'il n'y a pas de confirmation
        if (props.apiCheck) {
            props.apiCheck(props.objectCheckId).then(response => {
                if (response?.status === 200) {
                    if (props.checkMethod && !props.checkMethod(response, active)) {
                        return;
                    }
                    callApiFunction(activeState)
                }
            })
        }else{
            callApiFunction(activeState)
        }

    }

    return (
        <div onClick={props.onClick}>
            <ReactSwitch
                onChange={handleActiveSwitchChange}
                checked={active}
                className={props.className}
                id={`${props.idPrefix}-${props.objectId}`}
                onClick={() => { props.onClick }}
                onColor='#99e7fc'
                offColor='#fc9999'
            />
        </div>
    )
}
