import {Component} from 'react'
import {Api, Constants, Roles, Session} from 'scg.common-library'
import HtmlStructure from '../general/HtmlStructure'
import {TableWithFiltersSkeleton} from '../../services/LoadingHelper'
import moment from 'moment'
import Helper from '../../services/Helper'
import {InputField} from '../general/form/Input'
import {SelectField} from '../general/form/Select'
import ActiveSwitch from '../general/form/ActiveSwitch'
import NewUser from './UserForm'
import PrepareColBanned from './PrepareColBanned'
import ActionReason from '../general/form/ActionReason'
import Modal from '../general/form/Modal'
import {useTranslation, withTranslation} from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import i18next from 'i18next'
import {
    Replay as ReplayIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    FilterAlt as FilterAltIcon,
    FilterAltOff as FilterAltOffIcon,
    Block as BlockIcon,
    Lock as LockIcon,
    Warning as WarningIcon
} from "@mui/icons-material"
import {Tooltip} from "@mui/material"
import ScgDataTable from '../general/ScgDataTable'

import './users.css'

export default function Users({...props}) {
    const {t} = useTranslation()

    document.title =
        `${t('users.documentTitle')} - ` + Constants.DOCUMENT_TITLE_BACKOFFICE
    return (
        <HtmlStructure menuName='users' sectionClassName='users'>
            <UserList selectedUser={props.selectedUser} />
        </HtmlStructure>
    )
}

const UserList = withTranslation()(
    class extends Component {
        static defaultProps = {}

        constructor(props) {
            super(props)
            const tmpModifUser = props.selectedUser !== null
            const tmpNeedLoadDataUser = props.selectedUser !== null
            const tmpNeedLoadDataUserId = (props.selectedUser !== null) ? props.selectedUser : null
            this.state = {
                loading: true,
                users: [],
                totalRows: 0,
                perPage: Helper.getTablePerPageNumber(),
                newUser: false,
                modifyUser: tmpModifUser,
                needLoadDataUser: tmpNeedLoadDataUser,
                needLoadDataUserId: tmpNeedLoadDataUserId,
                showDelUserConfirmation: false,
                showInitPasswordConfirmation: false,
                handledUser: null,
                currentColBannedFormat: null,
                user: Session.getSessionUser(),
                userSocietyDetails: null
            }
            this.loadSocietyDetails()

            this.handlePerRowsChange = this.handlePerRowsChange.bind(this)
            this.handlePageChange = this.handlePageChange.bind(this)
            this.handleFilter = this.handleFilter.bind(this)
            this.handleModalVisibility = this.handleModalVisibility.bind(this)
            this.handleModalHidding = this.handleModalHidding.bind(this)
            this.handleLinesUpdate = this.handleLinesUpdate.bind(this)
        }

        async loadSocietyDetails() {
            try {
                const response = await Api.society.getSocietyDetails(Session.getSessionUser().society.id)
                const resultObject = Helper.isValidResponse(response)
                if (resultObject) {
                    this.setState({userSocietyDetails: resultObject})
                }
            } catch (error) {
                console.error("Failed to load society details", error)
            }
        }

        componentDidMount() {
            Api.user.getUserCount().then((response) => {
                const resultObject = Helper.isValidResponse(response)

                if (resultObject) {
                    this.setState({totalRows: resultObject.count})
                }
            })
            getUserLines.bind(this)(1)
        }

        getFilters() {
            console.debug('getFilters not initialized !')
            return []
        }

        handlePerRowsChange(newPerPage, page) {
            this.setState({perPage: newPerPage, loading: true}, () => {
                Helper.setTablePerPageNumber(newPerPage)
                getUserLines.bind(this)(page, this.getFilters())
            })
        }

        handlePageChange(page) {
            this.setState({loading: true}, () => {
                getUserLines.bind(this)(page, this.getFilters())
            })
        }

        handleLinesUpdate(userId, action = 'remove') {
            switch (action) {
                case 'remove':
                    this.setState((prevState) => {
                        const updatedUsers = prevState.users.filter(
                            (value) => value.id !== userId
                        )
                        return {users: updatedUsers}
                    })
                    break
                case 'add':
                    getUserLines.bind(this)(1)
                    break
                default:
                    break
            }
        }

        handleModalVisibility(type, user = null) {
            switch (type) {
                case 1:
                    this.setState({newUser: true})
                    break
                case 2:
                    this.setState({showDelUserConfirmation: true, handledUser: user})
                    break
                case 3:
                    this.setState({
                        showInitPasswordConfirmation: true,
                        handledUser: user
                    })
                    break
                default:
                    break
            }
        }

        handleModalHidding(type) {
            switch (type) {
                case 1:
                    this.setState({newUser: false})
                    break
                case 2:
                    this.setState({showDelUserConfirmation: false})
                    break
                case 3:
                    this.setState({showInitPasswordConfirmation: false})
                    break
                case 4:
                    this.setState({modifyUser: false})
                    break
                default:
                    break
            }
        }

        handleFilter(filterFunctionFromChild, updateButtonStateFunction) {
            this.setState({loading: true})
            updateButtonStateFunction(true)

            this.getFilters = filterFunctionFromChild
            const filters = this.getFilters()

            Api.user.getUserCount(Helper.getFiltersUrlParams(filters)).then((response) => {
                const resultObject = Helper.isValidResponse(response)
                if (resultObject) {
                    this.setState({totalRows: resultObject.count})
                }
            })

            getUserLines.bind(this)(1, filters, updateButtonStateFunction)
        }

        render() {
            console.log("🚀 ~ extends ~ render ~ this.state:", this.state.users)

            const {t} = this.props

            const columns = [
                {
                    name: t('tableColumnNames.id'),
                    selector: (row) => row.id,
                    center: true,
                    sortable: true,
                    width: '3vw'
                },
                {
                    name: t('tableColumnNames.name'),
                    selector: (row) => row.name.value,
                    sortable: true,
                    width: '14vw',
                    cell: (row) => row.name.span
                },
                {
                    name: t('tableColumnNames.level'),
                    selector: (row) => row.level.levelName.rank,
                    sortable: true,
                    width: '3vw',
                    cell: (row) => row.level.span
                },
                {
                    name: t('tableColumnNames.nickname'),
                    selector: (row) => row.pseudo.pseudoName,
                    sortable: true,
                    width: '9.5vw',
                    cell: (row) => row.pseudo.span
                },
                {
                    name: t('tableColumnNames.email'),
                    selector: (row) => row.mail.mailName,
                    sortable: true,
                    width: '15vw',
                    cell: (row) => row.mail.span
                },
                {
                    name: t('tableColumnNames.company'),
                    selector: (row) => row.society.societyName,
                    sortable: true,
                    width: '8vw',
                    cell: (row) => row.society.span
                },
                {
                    name: t('tableColumnNames.created'),
                    selector: (row) => new Date(row.creationDate.creationDateValue),
                    sortable: true,
                    width: '7vw',
                    cell: (row) => row.creationDate.span
                },
                {
                    name: t('tableColumnNames.banned.name'),
                    selector: (row) => row.banned.bannedName,
                    sortable: true,
                    center: true,
                    width: '7vw',
                    cell: (row) => row.banned.span
                },
                {
                    name: t('tableColumnNames.role'),
                    selector: (row) => row.role.roleName,
                    sortable: true,
                    center: true,
                    width: '7vw',
                    cell: (row) => row.role.span
                },
                {
                    name: t('tableColumnNames.active'),
                    selector: (row) => row.active.activeName,
                    sortable: true,
                    center: true,
                    width: '5vw',
                    cell: (row) => row.active.span
                },
                {
                    name: t('tableColumnNames.init'),
                    selector: (row) => row.init,
                    center: true,
                    width: '3vw'
                },
                {
                    name: t('tableColumnNames.suppr'),
                    selector: (row) => row.suppr,
                    center: true,
                    width: '4.5vw'
                }
            ]

            const activeLicence = Helper.isActivateLicence()

            return (
                <article className='users'>
                    <section className='bo-data-title'>
                        <h3>{t('users.title')}</h3>
                        {activeLicence &&
                            <>
                                <button
                                    className={`btn default addButton ${(!activeLicence) ? 'disabled' : ''} 
                                    ${(this.state.loading) ? 'disabled' : ''} 
                                    ${(this.state.user?.roles[0] === "ROLE_ADMIN" &&
                                        this.state.userSocietyDetails?.userMax <=
                                        this.state.userSocietyDetails?.userActive) ? 'disabled' : ''}`}
                                    title={t('users.addUserButton.title')}
                                    onClick={() => this.handleModalVisibility(1)}
                                >
                                    <span className='flex'>
                                        <AddIcon /> {' ' + t('users.addUserButton.name')}
                                    </span>
                                </button>
                                <>
                                    {(this.state.user?.roles[0] === "ROLE_ADMIN" &&
                                            this.state.userSocietyDetails?.userMax <=
                                            this.state.userSocietyDetails?.userActive) &&
                                        <div className='error-space'>
                                            <div className='error-maxUser'>
                                                <div className='icon-warning'>
                                                    <FontAwesomeIcon icon='fa-solid fa-circle-exclamation' />
                                                </div>
                                                {t('users.addModal.warning.userMax')}
                                            </div>
                                        </div>

                                    }
                                    {this.state.user?.roles[0] === "ROLE_ADMIN" &&
                                        <div className='userActive'>{t('users.userActive')} : <span
                                            className='userMax-label'>{this.state.userSocietyDetails?.userActive}</span>/<span
                                            className='userCount-label'>{this.state.userSocietyDetails?.userMax}</span>
                                        </div>
                                    }
                                </>
                            </>
                        }

                        {/*show new user form as modal dialog when buton is triggered*/}
                        <Modal
                            isShowing={this.state.newUser}
                            title={t('users.addUserModal.title')}
                            children={
                                <NewUser
                                    onClose={() => this.handleModalHidding(1)}
                                    callbackSuccess={() => this.handleLinesUpdate(null, 'add')}
                                />
                            }
                            hide={() => this.handleModalHidding(1)}
                        />

                        {/*show user form as modal dialog when modif needed*/}
                        <Modal
                            isShowing={this.state.modifyUser}
                            title={t('users.editUserModal.title')}
                            children={
                                <NewUser
                                    onClose={() => this.handleModalHidding(4)}
                                    callbackSuccess={() => this.handlePageChange(1)}
                                    userId={this.state.handledUser?.id}
                                    needLoadDataUser={this.state.needLoadDataUser}
                                    needLoadDataUserId={this.state.needLoadDataUserId}
                                />
                            }
                            hide={() => this.handleModalHidding(4)}
                        />

                        {/*show confirmation modal before deleting user*/}
                        <Modal
                            isShowing={this.state.showDelUserConfirmation}
                            title={t('users.deleteUserModal.title')}
                            children={
                                <ActionReason
                                    onClose={() => this.handleModalHidding(2)}
                                    objectId={this.state.handledUser?.id}
                                    apiFunction={Api.user.deleteUser}
                                    apiSuccessStatus={200}
                                    apiParametersObject={{comments: ''}}
                                    callbackSuccess={() =>
                                        this.handleLinesUpdate(this.state.handledUser?.id)
                                    }
                                    specificContent={true}
                                    specificCancel={t('users.deleteUserModal.cancelDelete')}
                                    specificValidation={t('users.deleteUserModal.validateDelete')}
                                    specificMessage={<GenerateRenderMessage
                                        style={{color: "red", textAlign: "center", maxWidth: 500}}
                                        children={t('users.deleteUserModal.warningDelete')}
                                    />}
                                />
                            }
                            hide={() => this.handleModalHidding(2)}
                        />

                        {/*show confirmation modal before init user's password*/}
                        <Modal
                            isShowing={this.state.showInitPasswordConfirmation}
                            title={t('users.resetPasswordUserModal.title')}
                            children={
                                <ActionReason
                                    onClose={() => this.handleModalHidding(3)}
                                    apiFunction={() =>
                                        Api.user.userPasswordForgotten(
                                            this.state.handledUser?.id,
                                            Session.getJwtToken()
                                        )
                                    }
                                    apiMethod='GET'
                                    withComments={false}
                                />
                            }
                            hide={() => this.handleModalHidding(3)}
                        />
                    </section>
                    <section className='bo-data-table card'>
                        {this.state.loading
                            &&
                            (<TableWithFiltersSkeleton linesCount={20} filtersCount={4} linesSize='large' />)}

                        <>
                            <UserFilters onFilter={this.handleFilter} />
                            <div className='bo-data-table-content' style={{}}>
                                <ScgDataTable
                                    columns={columns}
                                    data={this.state.users}
                                    paginationTotalRows={this.state.totalRows}
                                    paginationPerPage={this.state.perPage}
                                    onChangeRowsPerPage={this.handlePerRowsChange}
                                    onChangePage={this.handlePageChange}
                                    className='usersTable'
                                />
                            </div>
                        </>
                    </section>
                </article>
            )
        }
    }
)

function GenerateRenderMessage({style, ...props}) {
    return <div>
        <div
            style={style}
        >
            {props.children}
        </div>
    </div>
}

const UserFilters = withTranslation()(
    class extends Component {
        static defaultProps = {
            onFilter: () => { }
        }

        constructor(props) {
            super(props)
            this.state = {
                userFilterText: '',
                selectedBannedOptions: null,
                selectedActiveOptions: null,
                selectedRoleOptions: null,
                hasFilters: false,
                loading: false
            }
            this.bannedOptions = [
                {
                    label: props.t('users.filters.status.label'),
                    icon: 'fa-list-check',
                    options: [
                        {
                            value: true,
                            label: props.t('users.filters.status.options.first')
                        },
                        {
                            value: false,
                            label: props.t('users.filters.status.options.second')
                        }
                    ]
                }
            ]
            this.activeOptions = [
                {
                    label: props.t('users.filters.state.label'),
                    icon: 'fa-table-list',
                    options: [
                        {
                            value: true,
                            label: props.t('users.filters.state.options.first')
                        },
                        {
                            value: false,
                            label: props.t('users.filters.state.options.second')
                        }
                    ]
                }
            ]
            this.roleOptions = [
                {
                    label: props.t('users.filters.role.label'),
                    icon: 'fa-table-list',
                    options: [
                        {
                            value: Constants.ROLE_SUPER_ADMIN,
                            label: props.t('users.roles.superAdmin')
                        },
                        {
                            value: Constants.ROLE_ADMIN,
                            label: props.t('users.roles.admin')
                        },
                        {
                            value: Constants.ROLE_PLAYER,
                            label: props.t('users.roles.player')
                        }
                    ]
                }
            ]

            this.handleFilterSubmit = this.handleFilterSubmit.bind(this)
            this.handleClearFilter = this.handleClearFilter.bind(this)
            this.handleBannedChange = this.handleBannedChange.bind(this)
            this.handleActiveChange = this.handleActiveChange.bind(this)
            this.handleRoleChange = this.handleRoleChange.bind(this)
            this.handleTextInput = this.handleTextInput.bind(this)
            this.updateButtonState = this.updateButtonState.bind(this)
            this.getFilters = this.getFilters.bind(this)
        }

        getFilters() {
            let filters = {}

            if (this.state.userFilterText !== '') {
                filters.userFilterText = this.state.userFilterText
            }

            if (
                this.state.selectedBannedOptions !== null &&
                Object.keys(this.state.selectedBannedOptions).length !== 0
            ) {
                filters.banned = this.state.selectedBannedOptions.value
            }

            if (
                this.state.selectedActiveOptions !== null &&
                Object.keys(this.state.selectedActiveOptions).length !== 0
            ) {
                filters.active = this.state.selectedActiveOptions.value
            }

            if (
                this.state.selectedRoleOptions !== null &&
                Object.keys(this.state.selectedRoleOptions).length !== 0
            ) {
                filters.role = this.state.selectedRoleOptions.value
            }

            this.setState(() => {
                return {hasFilters: Object.keys(filters).length !== 0}
            })

            return filters
        }

        handleBannedChange(selectedOptions) {
            this.setState({
                selectedBannedOptions: selectedOptions
            })
        }

        handleActiveChange(selectedOptions) {
            this.setState({
                selectedActiveOptions: selectedOptions
            })
        }

        handleRoleChange(selectedOptions) {
            this.setState({
                selectedRoleOptions: selectedOptions
            })
        }

        handleTextInput(e) {
            if (e.key === 'Enter') {
                this.handleFilterSubmit()
            }
        }

        handleFilterSubmit() {
            this.props.onFilter(this.getFilters, this.updateButtonState)
        }

        handleClearFilter() {
            this.setState(
                {
                    userFilterText: '',
                    selectedBannedOptions: [],
                    selectedActiveOptions: [],
                    selectedRoleOptions: [],
                    hasFilters: false
                },
                () => {
                    this.handleFilterSubmit()
                }
            )
        }

        updateButtonState(isLoading = false) {
            this.setState({loading: isLoading})
        }

        render() {
            const {t} = this.props

            return (
                <div className='bo-data-filters'>
                    <InputField
                        className='users-text'
                        type='text'
                        name='userFilterText'
                        size='tiny'
                        value={this.state.userFilterText}
                        onKeyDown={this.handleTextInput}
                        title={t('searchInput.title')}
                        context={this}
                    >
                        {t('searchInput.label')}
                    </InputField>
                    <SelectField
                        className='formField'
                        classNamePrefix='reactSelect-custom'
                        options={this.activeOptions}
                        value={this.state.selectedActiveOptions}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder={t('users.filters.state.label')}
                        onChange={this.handleActiveChange}
                        context={this}
                    />
                    <SelectField
                        className='formField'
                        classNamePrefix='reactSelect-custom'
                        options={this.roleOptions}
                        value={this.state.selectedRoleOptions}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder={t('users.filters.role.label')}
                        onChange={this.handleRoleChange}
                        context={this}
                    />
                    <SelectField
                        className='formField'
                        classNamePrefix='reactSelect-custom'
                        options={this.bannedOptions}
                        value={this.state.selectedBannedOptions}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder={t('users.filters.status.label')}
                        onChange={this.handleBannedChange}
                        context={this}
                    />
                    <div>
                        <button
                            type='button'
                            className='btn default filterButton'
                            title={t('filterButtonTitles.results')}
                            disabled={this.state.loading}
                            onClick={this.handleFilterSubmit}
                        >
                            <FilterAltIcon />
                        </button>
                        {this.state.hasFilters && (
                            <button
                                type='button'
                                className='btn warning resetButton'
                                title={t('filterButtonTitles.clear')}
                                onClick={this.handleClearFilter}
                            >
                                <FilterAltOffIcon />
                            </button>
                        )}
                    </div>
                </div>
            )
        }
    }
)

/* ================================== GLOBAL FUNCTIONS ================================== */
/**
 * Call questions Api and update table state with the result of the api call
 *
 * @param page {int}
 * @param filters {object|null}
 * @param callback {Function|null}
 * @return {Promise<void>}
 */
async function getUserLines(page = 1, filters = null, callback = null) {
    const sessionUser = Session.getSessionUser()
    let urlParams = {'order[id]': 'desc', per_page: this.state.perPage}
    if (!Roles.isGranted(sessionUser.roles[0], Constants.ROLE_SUPER_ADMIN)) {
        urlParams.societies = sessionUser.society.id
    }
    Helper.getDataTableLines.bind(this)(
        Api.user.getUsers,
        urlParams,
        'users',
        FormatRow,
        page,
        filters,
        callback
    )
}

/**
 * Create a jsx object that will be interpreted by the DataTable
 *
 * @param user {object}
 */
async function FormatRow(user) {
    let active,
        del,
        name,
        username = `${user.name} ${user.surname}`
    let initPassword = Helper.FormatText('-')
    const currentUser = Session.getUser()

    const activeLicence = Helper.isActivateLicence()
    /**
     * @description: Fonction pour vérifier si le nombre d'utilisateurs actifs est inférieur au nombre maximum d'utilisateurs autorisés
     * @description: Exécutée quand on veut activer ou désactiver un utilisateur
     */
    const checkUserCount = (response, objectActive) => {
        const societyDetails = Helper.isValidResponse(response)
        if (!objectActive && (societyDetails?.userMax <= societyDetails?.userActive)) {
            Helper.displayMessage(i18next.t('users.addModal.warning.userMax'), "error");
            return false
        }
        return true
    }
    /**
     * @description: Fonction pour mettre à jour les détails de la société après modification de l'état d'un switch
     */
    const updateSocietyDetails = async () => {
        const response = await Api.society.getSocietyDetails(user.society.id);
        const societyDetails = Helper.isValidResponse(response)
        if (societyDetails) {
            this.setState({ userSocietyDetails: societyDetails })
        }
    }

    let banned = <PrepareColBanned user={user} callbackSuccess={modifyUserBannedStatus} />
    if (user.email === currentUser.username || !Roles.isGranted(currentUser.roles[0], user.roles[0])) {
        active = Helper.FormatText(user.active ? '✔' : 'x')
        name = Helper.FormatText(username)
        del = Helper.FormatText('-')
    } else {
        name = <a href='/' className='bo-data-table_clickable'
                  onClick={e => {
                      e.preventDefault()
                      this.setState({modifyUser: true, handledUser: user})
                  }}>
            {Helper.FormatLink(username, 'bo-data-table_clickable', null)}
        </a>

        active = <ActiveSwitch
            objectActive={user.active}
            objectId={user.id}
            apiFunction={Api.user.updateUser}
            onChange={updateSocietyDetails}
            apiCheck={Api.society.getSocietyDetails}
            objectCheckId={user.society.id}
            checkMethod={checkUserCount}
            showErrorCheck={i18next.t('users.addModal.warning.userMax')}
        />

        initPassword = <span className='user-delete'
                             title={`${i18next.t('users.formatRow.initPasswordTitle')} ${user.pseudo}`}>
            <ReplayIcon color='info' onClick={() => this.handleModalVisibility(3, user)} />
        </span>

        del = <span className='user-delete'
                    title={`${i18next.t('users.formatRow.deleteAccountTitle')} ${user.pseudo}`}>
            <DeleteIcon color='error' onClick={() => this.handleModalVisibility(2, user)} />
        </span>
    }

    if (!activeLicence) {
        active = Helper.FormatText(user.active ? '✔' : 'x')
        name = Helper.FormatText(username)
        initPassword = Helper.FormatText('-')
        del = Helper.FormatText('-')
        banned = (user.banned) ? i18next.t('tableColumnNames.banned.yes') : i18next.t(
            'tableColumnNames.banned.no')
    }

    const fieldName = {
        span: (<span className='flex'>
            {name}
                {user.firstActivationNeeded &&
                    <Tooltip placement='top' title={i18next.t('users.formatRow.activatedAccountTitle')}>
                        <BlockIcon className='user-warning' />
                    </Tooltip>}
                {user.needReactivate &&
                    <Tooltip placement='top' title={i18next.t('users.formatRow.suspendedAccountTitle',
                        {'number': user.incorrectLogins, 'date': user.incorrectLoginAt})}>
                        <LockIcon className='user-alert' />
                    </Tooltip>}
                {(user.incorrectLogins >= 5 && !user.needReactivate) &&
                    <Tooltip placement='top' title={`${i18next.t('users.addModal.warning.userBlock.message',
                        {failed_count: user.incorrectLogins})}`}>
                        <WarningIcon className='user-blocked' />
                    </Tooltip>}
        </span>
        ),
        value: user.name
    }

    const pseudo = {
        pseudoName: `${user.pseudo} #${user.tag}`,
        span: Helper.FormatUsername(`${user.pseudo}`, <span className='user-tag'>{`#${user.tag}`}</span>,
            `${user.pseudo}#${user.tag}`)

    }

    return {
        id: user.id,
        name: fieldName,
        pseudo: pseudo,
        mail: {mailName: user.email, span: Helper.FormatText(user.email)},
        society: {societyName: user.society.name, span: Helper.FormatText(user.society.name)},
        creationDate: {
            creationDateValue: user.creationDate,
            span: Helper.FormatText(moment(user.creationDate).format('DD/MM/YYYY HH:mm'))
        },
        banned: {bannedName: user.banned, span: banned},
        role: {roleName: user.roles[0], span: Helper.FormatText(Roles.getRoleString(user.roles[0]))},
        level: {levelName: user.level, span: Helper.FormatLevel(user.level, false)},
        active: {activeName: user.active, span: active},
        init: initPassword,
        suppr: del
    }

    function modifyUserBannedStatus() {
        user.banned = !user.banned
    }
}
