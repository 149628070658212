import React from 'react'
import { createPortal } from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import Statistics from './Statistics'

const StatisticsModal = ({ user, open, onClose }) => {
    const {t} = useTranslation()
    return open 
        ? createPortal(
            <>
                <section className="modal-wrapper modal-statistics">
                    <div className="modal-overlay" />
                    <div className="modal">
                        <div className="modal-header">
                            <h4>{t('societies.statistics.modalTitle', {"nom":user?.name, "prenom": user?.surname})}</h4>
                            <button
                                type="button"
                                className="btn alert modal-close-button"
                                title={t('general.form.modal.closeButtonTitle')}
                                onClick={onClose}
                            >
                            <FontAwesomeIcon icon="fas fa-circle-xmark" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <Statistics userId={user?.id} />
                        </div>
                    </div>
                </section>
            </>,
            document.body
        )
        : null;
};

export default StatisticsModal;
