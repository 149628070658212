import {Component} from 'react'
import {Navigate} from 'react-router-dom'
import {LoadingOverlay} from '../general/form/Loading'
import {InputField} from '../general/form/Input'
import {Api, Constants, Session} from 'scg.common-library'
import {routes as Routing} from '../../services/RoutesHelper'
import {withTranslation} from 'react-i18next'

import './login.css'

export function LoginPage() {
    return <Login />
}

export function LogoutPage() {
    Session.handleLogout()
    return <Navigate to={Routing.app_login} />
}

const Login = withTranslation()(
    class extends Component {
        constructor(props) {
            super(props)
            this.state = {
                isSubmited: false,
                isRequestDone: false,
                identifier: '',
                password: '',
                error: {
                    status: false,
                    message: ''
                },
                notification: {
                    status: false,
                    message: ''
                }
            }
            this.handleSubmit = this.handleSubmit.bind(this)
            this.handleForgottenPassword = this.handleForgottenPassword.bind(this)
            this.handlePasswordChange = this.handlePasswordChange.bind(this)
            this.handleIdentifierChange = this.handleIdentifierChange.bind(this)
        }

        async handleSubmit(e) {
            e.preventDefault()
            if (this.state.identifier === '' || this.state.password === '') {
                this.setState({
                    error: {
                        status: true,
                        message: this.props.t('login.errors.emptyInputs')
                    },
                    notification: {
                        status: false
                    }
                })
            } else {
                this.setState({isSubmited: true})

                const response = await Api.user.authenticateUser(
                    this.state.identifier,
                    this.state.password
                )

                if (response?.status !== 200) {
                    let message = this.props.t('login.errors.response')
                    if (response?.code === Constants.HTTP_USER_NOT_FOUND) {
                        message = this.props.t('login.errors.userDoesNotExist')
                    } else if (response?.code === Constants.HTTP_INVALID_PASSWORD) {
                        message = this.props.t('login.errors.incorrectPassword')
                    } else if (response?.code === Constants.HTTP_INVALID_TOKEN) {
                        message = this.props.t('login.errors.incorrectToken')
                    } else if (response?.code === Constants.HTTP_TOKEN_EXPIRED) {
                        message = this.props.t('login.errors.expiredToken')
                    } else if (response?.code === Constants.HTTP_BANNED_USER) {
                        message = this.props.t('login.errors.bannedAccount')
                    }
                    this.setState(this.getGenericError(message))
                } else {
                    const data = response.data

                    const user = {
                        id: data.id,
                        email: data.email,
                        pseudo: data.pseudo,
                        name: data.name,
                        surname: data.surname,
                        roles: data.roles,
                        society: data.society,
                        language: data.language
                    }

                    Session.handleLogin({
                        user: user,
                        jwtToken: data.token,
                        refreshToken: data.refreshToken
                    })

                    this.setState({
                        isRequestDone: true,
                        isSubmited: false
                    })
                }
            }
        }

        getGenericError(message = this.props.t('login.errors.oops')) {
            return {
                isRequestDone: false,
                isSubmited: false,
                error: {
                    status: true,
                    message: message
                },
                notification: {
                    status: false
                }
            }
        }

        handleIdentifierChange(e) {
            this.setState({
                identifier: e.target.value,
                error: {status: false},
                notification: {status: false}
            })
        }

        handlePasswordChange(e) {
            this.setState({
                password: e.target.value,
                error: {status: false},
                notification: {status: false}
            })
        }

        async handleForgottenPassword(e) {
            e.preventDefault()
            if (this.state.identifier === '') {
                this.setState({
                    error: {
                        status: true,
                        message: this.props.t('login.form.required')
                    },
                    notification: {
                        status: false
                    }
                })
            } else {
                this.setState({isSubmited: true})
                const response = await Api.user.checkUserIdentifier(
                    this.state.identifier
                )
                if (response.status !== 200) {
                    if (response.code === Constants.HTTP_USER_NOT_FOUND) {
                        this.setState({
                            isRequestDone: false,
                            isSubmited: false,
                            error: {
                                status: true,
                                message: this.props.t('login.form.userDoesNotExist')
                            },
                            notification: {
                                status: false
                            }
                        })
                    } else {
                        this.setState(this.getGenericError())
                    }
                } else {
                    const data = response.data
                    const forgotResponse = await Api.user.userPasswordForgotten(data.id)

                    if (forgotResponse.status !== 200) {
                        this.setState(this.getGenericError())
                    }

                    this.setState({
                        isRequestDone: true,
                        isSubmited: false,
                        notification: {
                            status: true,
                            message: this.props.t('login.form.sentResetLink')
                        },
                        error: {
                            status: false
                        }
                    })
                }
            }
        }

        render() {
            const {t} = this.props

            return (
                <div className='login'>
                    {Session.isLoggedIn() && <Navigate to={Routing.app_home} />}
                    {this.state.isSubmited && !this.state.isRequestDone && (
                        <LoadingOverlay />
                    )}

                    <img
                        className='compagny-logo'
                        src='/favicons/mstile-150x150.png'
                        alt='Habaka® logo'
                    />
                    <form onSubmit={this.handleSubmit} className='login-form'>
                        <h1 className='login-title'>
                            {Constants.DOCUMENT_TITLE_BACKOFFICE}
                        </h1>
                        {this.state.error.status && (
                            <p className='login-error'>{this.state.error.message}</p>
                        )}
                        {this.state.notification.status && (
                            <p className='login-notification'>
                                {this.state.notification.message}
                            </p>
                        )}
                        <InputField
                            name='identifier'
                            value={this.state.identifier}
                            required={true}
                            onChange={this.handleIdentifierChange}
                            autocomplete='username'
                            children={t('login.inputs.identifier')}
                        />
                        <InputField
                            type='password'
                            name='password'
                            value={this.state.password}
                            required={true}
                            onChange={this.handlePasswordChange}
                            autocomplete='current-password'
                            children={t('login.inputs.password')}
                        />

                        <button
                            type='submit'
                            name='loginButton'
                            className='btn default login-button'
                            title={t('session.connect')}
                        >
                            {t('session.connect')}
                        </button>
                        <a
                            href={Routing.app_login}
                            className='login-forgotten-password'
                            onClick={this.handleForgottenPassword}
                        >
                            {t('login.forgotPasswordLink')}
                        </a>
                    </form>
                    <img
                        className='site-logo'
                        src='/img/Nextaura2.png'
                        alt={t('login.logo.alt')}
                        title={t('login.logo.title')}
                    />
                    <div className='site-version'>
                        {t('login.version')} {process.env.REACT_APP_VERSION}
                    </div>
                </div>
            )
        }
    }
)
