import { Constants } from 'scg.common-library';
import HtmlStructure from '../general/HtmlStructure';
import UserMenu from './UserMenu/UserMenu';
import Activities from './Activities/Activities';
import QuickLinks from './QuickLinks/QuickLinks';
import Statistics from './Statistics/Statistics';
import GeneralParameters from './GeneralParameters/GeneralParameters';
import { useDate } from '../../hooks/customHooks';
import { routes as Routing } from '../../services/RoutesHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './dashboard.css';

export default function Dashboard() {
  const { t } = useTranslation();

  document.title =
    `${t('dashboard.documentTitle')} - ` + Constants.DOCUMENT_TITLE_BACKOFFICE;

  return (
    <HtmlStructure menuName="Dashboard" sectionClassName="dashboard">
      <DateTime />
      <UserMenu />
      <Activities />
      <div className="dashboard-rightPanel">
        <Statistics />
        <GeneralParameters />
      </div>
      <QuickLinks />
      <Helpcenter />
    </HtmlStructure>
  );
}

function DateTime() {
  const [currentDate] = useDate(10000); // 10s. interval
  return (
    <>
      <div className="dashboard-datetime">
        <span className="dashboard-time">{currentDate.format('HH:mm')}</span>
        <span className="dashboard-date">
          {currentDate.format('dddd DD MMMM YYYY')}
        </span>
      </div>
    </>
  );
}

function Helpcenter() {
  const { t } = useTranslation();

  return (
    <>
      <div className="dashboard-help">
        <h3>{t('dashboard.helpCenter.title')}</h3>
        <div>
          <Link
            to={Routing.bo_dashboard}
            title={t('dashboard.helpCenter.helpLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-circle-question" />
            <span>{t('dashboard.helpCenter.helpLink.name')}</span>
          </Link>
          <Link
            to={Routing.bo_dashboard}
            title={t('dashboard.helpCenter.supportLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-headset" />
            <span>{t('dashboard.helpCenter.supportLink.name')}</span>
          </Link>
          <Link
            to={Routing.bo_changelog}
            title={t('dashboard.helpCenter.changelogLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-file-contract" />
            <span>{t('dashboard.helpCenter.changelogLink.name')}</span>
          </Link>
          <Link
            to={Routing.bo_dashboard}
            title={t('dashboard.helpCenter.settingsLink.title')}
          >
            <FontAwesomeIcon icon="fas fa-cog" />
            <span>{t('dashboard.helpCenter.settingsLink.name')}</span>
          </Link>
        </div>
      </div>
    </>
  );
}
