import React, { useState, useEffect, useCallback } from 'react'
import moment from "moment";
import styled from '@emotion/styled'
import ReactSwitch from "react-switch";
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next"
import {DateTimePicker} from "@mui/x-date-pickers";
import {Delete as DeleteIcon} from '@mui/icons-material'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {
    Box,
    Tab,
    Tabs,
    Grid,
    Alert,
    Radio,
    Popover,
    Typography,
    TextField,
    RadioGroup,
    FormControl,
    FormHelperText,
    CircularProgress,
    FormControlLabel,
} from "@mui/material"
import Modal from '../../general/form/Modal'
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import {Api, Constants} from "scg.common-library";
import Helper from '../../../services/Helper'
import {TableSkeleton} from "../../../services/LoadingHelper";
import {Loading} from "../../general/form/Loading";

const Label = styled.label`
    display: block;
    font-size: 1.17em;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 1em;
`
const DeleteButton = styled.span`
    display: inline-block;
    cursor: pointer;
`
const Textarea = styled(TextareaAutosize)`
    width: 100%;
    height: 6em !important;
    border-radius: 4px;
    padding: 16px 14px;
    box-sizing: border-box;
    resize: none;
    border: 1px solid ${props => props.error ? '#d32e2f' : '#c4c4c4' };
`
const FormControlMessage = styled(FormHelperText)`
    margin: 3px 0 0;
`

const TabPanel = ({ children, value, index, ...other }) => {
    return <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
}

const FormView = () => {
    // const
    const today = moment().startOf('day')
    // states
    const [errors, setErrors] = useState([])
    const [local, setLocal] = useState('fr')
    const [isSaving, setIsSaving] = useState(false)
    const [etat, setEtat] = useState('')
    const [type, setType] = useState('')
    const [titre, setTitre] = useState('')
    const [message, setMessage] = useState('')
    const [dateDebut, setDateDebut] = useState(today)
    const [dateFin, setDateFin] = useState(dateDebut)
    const [disallowDate, setDisallowDate] = useState(true)
    // hooks
    const {t, i18n} = useTranslation()
    // lifecycle
    useEffect(() => {
        if( i18n.hasOwnProperty('resolvedLanguage') ){
            const lang = i18n.resolvedLanguage
            setLocal(lang)
            moment.locale(lang)
            Helper.importLocale(lang)
        }
    }, [i18n]);
    // callback
    const handleChange = (field, value) =>{
        switch (field){
            case 'etat':
                setEtat(value)
                break
            case 'titre':
                setTitre(value)
                break
            case 'message':
                setMessage(value)
                break
            case 'dateDebut':
                if(value){
                    setDateDebut(value)
                }
                break
            case 'dateFin':
                if(value){
                    setDateFin(value)
                }
                break
            default:
                setType(value)
                setDisallowDate(false)
                if( value === 'direct' ){
                    setDisallowDate(true)
                }
        }
    }
    // function
    const resetForm = () => {
        setEtat('')
        setTitre('')
        setType('')
        setTitre('')
        setDateDebut(today)
        setDateFin(today)
        setMessage('')
        setErrors([])
        setDisallowDate(true)
    }
    // validation
    const validateForm = () => {
        let errors = []
        if( etat === '' || etat === null || etat === undefined ){
            errors.push('etat')
        }
        if( type === '' || type === null || type === undefined ){
            errors.push('type')
        }
        if( titre === '' || titre === null || titre === undefined ){
            errors.push('titre')
        }
        if( message === '' || message === null || message === undefined ){
            errors.push('message')
        }
        setErrors(errors)
        return !errors.length
    }
    // callback
    const handleSaveMessage = (evt) => {
        evt.preventDefault()
        let startDate = null
        let endDate = null
        if( validateForm() ){
            setIsSaving(true)
            if( type === 'program' ){
                startDate = dateDebut.format('YYYYMMDDHHmm')
                endDate = dateFin.format('YYYYMMDDHHmm')
            }
            Api.message.createMessage(titre,message,etat === 'enable', type === 'program', startDate, endDate)
                .then( (response) => {
                    if( response.status === Constants.HTTP_CREATED ){
                        setIsSaving(false)
                        resetForm()
                        Helper.displayMessage(t('system.information.messageAddSuccess'))
                    }
                } )
        }else{
            Helper.displayMessage(t('system.information.validationError'), 'error')
        }
    }

    return(
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={local}>
            <form method="post" onSubmit={handleSaveMessage} noValidate>
                <Box>
                    <Grid container spacing={2} mb={3}>
                        <Grid item xs={6}>
                            <Label style={{ marginBottom: 2 }}>{t('system.information.labelEtat')}</Label>
                            <FormControl error={errors.includes('etat')} >
                                <RadioGroup
                                    row
                                    value={etat}
                                    name="row-radio-buttons-group"
                                    onChange={(event, value) => handleChange('etat', value)}
                                >
                                    <FormControlLabel value="enable" control={<Radio  />} label={t('system.information.radioEnable')} />
                                    <FormControlLabel value="disable" control={<Radio />} label={t('system.information.radioDisable')} />
                                </RadioGroup>
                                { errors.includes('etat') && <FormControlMessage margin={0}>{t('system.requiredField')}</FormControlMessage> }
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Label style={{ marginBottom: 2 }}>{t('system.information.labelProgrammation')}</Label>
                            <FormControl error={errors.includes('type')}>
                                <RadioGroup
                                    row
                                    value={type}
                                    name="row-radio-buttons-group"
                                    onChange={(event, value) => handleChange('type', value)}
                                >
                                    <FormControlLabel value="direct" control={<Radio  />} label={t('system.information.radioDirect')} />
                                    <FormControlLabel value="program" control={<Radio />} label={t('system.information.radioProgram')} />
                                </RadioGroup>
                                { errors.includes('type') && <FormControlMessage margin={0}>{t('system.requiredField')}</FormControlMessage> }
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={3}>
                        <Grid item xs={6}>
                            <Label>{t('system.information.labelDebut')}</Label>
                            <DateTimePicker
                                minDateTime={today}
                                value={dateDebut}
                                sx={{ width: '100%' }}
                                disabled={disallowDate}
                                onChange={(value) => handleChange('dateDebut', value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Label>{t('system.information.labelFin')}</Label>
                            <DateTimePicker
                                value={dateFin}
                                minDateTime={ dateDebut ? dateDebut : moment() }
                                sx={{ width: '100%' }}
                                disabled={disallowDate}
                                onChange={(value) => handleChange('dateFin', value)}
                            />
                        </Grid>
                    </Grid>
                    <Box mb={3}>
                        <Label>{t('system.information.labelTitre')}</Label>
                        <FormControl error={errors.includes('titre')} style={{ width: '100%' }}>
                            <TextField
                                value={titre}
                                sx={{ width: '100%' }}
                                error={errors.includes('titre')}
                                onChange={(evt) => handleChange('titre', evt.target.value)}
                            />
                            { errors.includes('titre') && <FormControlMessage margin={0}>{t('system.requiredField')}</FormControlMessage> }
                        </FormControl>
                    </Box>
                    <Box mb={3}>
                        <Label>{t('system.information.labelMessage')}</Label>
                        <FormControl error={errors.includes('message')} style={{ width: '100%' }}>
                            <Textarea
                                value={message}
                                error={errors.includes('message')}
                                onChange={(evt) => handleChange('message', evt.target.value)}
                            />
                            { errors.includes('message') && <FormControlMessage margin={0}>{t('system.requiredField')}</FormControlMessage> }
                        </FormControl>
                    </Box>
                    <Box>

                        <button className={`btn btn-lg default`} title={t('system.information.btnProgram')} disabled={isSaving}>
                            {t('system.information.btnProgram')} {isSaving && <CircularProgress size={16}/> }
                        </button>
                        <input
                            type="button"
                            className="btn btn-lg warning"
                            value={t('system.information.btnCancel')}
                            onClick={() => resetForm()}
                        />
                    </Box>
                </Box>
            </form>
        </LocalizationProvider>

    )
}

const Message = ({message}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Typography
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {message}
            </Typography>
            <Popover
                open={open}
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>{message}</Typography>
            </Popover>
        </>
    )
}

const Switch = ({ id, value, onSwitch }) => {
    // callback
    const handleSwitch = (toggle) => {
        if( typeof(onSwitch) === 'function' ){
            onSwitch(id, toggle)
        }
    }
    return (
        <ReactSwitch
            checked={value}
            onColor='#99e7fc'
            offColor='#fc9999'
            onChange={handleSwitch}
        />
    )
}

const DeleteAction = ({ id, onDelete }) => {
    const {t} = useTranslation()
    // callback
    const handleDelete = () => {
        if( typeof(onDelete) === 'function'){
            onDelete(id)
        }
    }
    return (
        <DeleteButton title={t('system.information.altDelete')}>
            <DeleteIcon onClick={handleDelete} />
        </DeleteButton>
    )
}

const ListView = () => {
    // states
    const [paged, setPaged] = useState(1)
    const [rows,setRows] = useState(0)
    const [perPage, setPerPage] = useState(Helper.getTablePerPageNumber)
    const [messageId, setMessageId] = useState(null)
    const [messages, setMessages] = useState([])
    const [isDeleting, setIsDeleting] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    // hooks
    const {t, i18n} = useTranslation()
    // lifecycle
    useEffect(() => {
        getMessages()
    }, []);
    // tableColumns
    const columns = [
        {
            name: t('system.information.labelTitre'),
            selector: (row) => row.title,
            sortable: true,
            width: '8vw'
        },
        {
            name: t('system.information.labelMessage'),
            selector: (row) => row.description,
            width: '12vw',
            format : (row) => <Message message={row.description} />

        },
        {
            name: t('system.information.columnType'),
            selector: (row) => row.isProgram,
            width: '5vw',
            format : (row) => {
                return row.isProgram ? t('system.information.radioProgram') : t('system.information.radioDirect')
            }
        },
        {
            name: t('system.information.labelDebut'),
            selector: (row) => row.startDate,
            width: '7vw',
            format: (row) => {
                if( row.startDate ) {
                    return Helper.formatDateByUserLocale(row.startDate, i18n)
                }
                return  '-'
            },
        },
        {
            name: t('system.information.labelFin'),
            selector: (row) => row.endDate,
            format: (row) => {
                if( row.endDate ) {
                    return Helper.formatDateByUserLocale(row.endDate, i18n)
                }
                return  '-'
            },
            width: '7vw'
        },
        {
            name: t('system.information.columnActive'),
            width: '5vw',
            selector: (row) => row.active,
            format :  row => <Switch
                id={row.id}
                value={row.active}
                onSwitch={handleStatusChange}
            />
        },
        {
            name: t('system.information.columnAction'),
            selector: (row) => row.id,
            center: true,
            width: '4vw',
            format : (row) => <DeleteAction
                id={row.id}
                onDelete={handleDeleteMessage}
            />
        },
    ]
    // callback
    const handleStatusChange = (id, status) => {
        setIsLoading(true)
        if(id){
            Api.message.updateMessage(id, {"active" : status})
                .then( response => {
                    if( Constants.HTTP_OK === response.status ){
                        getMessages()
                        if(status){
                            Helper.displayMessage(t('system.information.messageActiveSuccess'))
                        }else{
                            Helper.displayMessage(t('system.information.messageDisableSuccess'))
                        }
                    }else{
                        Helper.displayMessage(t('general.form.activeSwitch.error'), 'error')
                    }
                } )
                .catch(() => Helper.displayMessage(t('general.form.activeSwitch.error'), 'error'))
        }
    }
    const handleDeleteMessage = (id) => {
        if(id){
            setMessageId(id)
            setShowDeleteModal(true)
        }
    }
    const handleModalClose = useCallback(() => {
        setShowDeleteModal(false)
    }, [])
    const handleModalConfirmation = useCallback( () => {
        if(messageId){
            setIsDeleting(true)
            Api.message.deleteMessage(messageId)
                .then( (response) => {
                    if( response.status === Constants.HTTP_NO_CONTENT ){
                        const messageList = messages.filter( message => message.id !== messageId )
                        setMessages(messageList)
                        setIsDeleting(false)
                        setShowDeleteModal(false)
                        Helper.displayMessage(t('system.information.messageDelSuccess'))
                    }else{
                        Helper.displayMessage(t('general.form.errors.response'), 'error')
                    }
                } )
                .catch( () => Helper.displayMessage(t('general.form.errors.response'), 'error') )
        }
    }, [messageId] )
    // function
    const getMessages = (page =null, per = null) => {
        setIsLoading(true)
        const pageIndex = page ? page : paged
        const perIndex = per ? per : perPage
        Promise.all([
            Api.message.getMessages(pageIndex, {per_page: perIndex}),
            Api.message.getMessageCount()
        ])
            .then( ([ messagesResponse, messageCountResponse ]) => {
                if( messagesResponse.status === Constants.HTTP_OK &&  messageCountResponse.status ===  Constants.HTTP_OK  ){
                    if(messagesResponse.data.length  ){
                        setMessages(messagesResponse.data)
                    }
                    setRows(messageCountResponse.data.count)
                }
            } )
            .finally( () => setIsLoading(false) )
    }
    // function render
    const renderMessages = () => {
        if( !messages.length ){
            return <Alert severity="info">{t('system.noData')}</Alert>
        }
        return <DataTable
            columns={columns}
            data={messages}
            fixedHeader
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={rows}
            onChangeRowsPerPage={(perPage, page) => {
                setPaged(page)
                setPerPage(perPage)
                getMessages(page, perPage)
                Helper.setTablePerPageNumber(perPage)
            }}
            onChangePage={(page) => {
                setPaged(page)
                getMessages(page)
            }}
            paginationComponentOptions={{
                rowsPerPageText: t('general.utils.rows_per_page')
            }}
            progressPending={isLoading}
            progressComponent={<TableSkeleton linesCount={20} />}
            responsive
            className='usersTable'
            subHeaderAlign='center'
            subHeaderWrap
        />
    }


    return (
        <>
            { renderMessages() }
            <Modal
                title={t('system.information.modalTitle')}
                hide={handleModalClose}
                isShowing={showDeleteModal}
            >
                {isDeleting && <Loading />}
                <Box padding={3}>
                    <Typography style={{ textAlign: "center", paddingBottom: 20 }}>
                        <strong>{t('system.information.modalSubtitle')}</strong> <br/>
                        {t('system.information.modalMessage')}
                    </Typography>
                    <div style={{ textAlign: "center" }}>
                        <input
                            type='button'
                            className='btn alert'
                            value={t('tableColumnNames.actions.modal.yesDelete')}
                            onClick={handleModalConfirmation}
                        />
                        <input
                            type='button'
                            className='btn error'
                            value={t('tableColumnNames.actions.modal.close')}
                            onClick={() => setShowDeleteModal(false)}
                        />
                    </div>
                </Box>
            </Modal>
        </>
    )
}

const InformationBlock = () => {
    const [view, setView] = useState(0)
    const {t} = useTranslation()
    const handleChange = (event, view) => {
        setView(view)
    }
    return (
        <div className='block information-block'>
            <div className="block-head">
                <h2 className='block-title'>{t('system.information.title')}</h2>
            </div>
            <div className='block-content'>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={view} onChange={handleChange}>
                        <Tab label={t('system.information.tabForm')} />
                        <Tab label={t('system.information.tabList')}  />
                    </Tabs>
                </Box>
                <TabPanel value={view} index={0}>
                    <FormView />
                </TabPanel>
                <TabPanel value={view} index={1}>
                    <ListView />
                </TabPanel>
            </div>
        </div>
    )
}


export default  InformationBlock