import {Constants} from 'scg.common-library'
import HtmlStructure from '../general/HtmlStructure'
import {useTranslation} from 'react-i18next'
import InformationBlock from "./information/InformationBlock";
import './system.css'

const MaintenanceBlock = () => {
  const {t} = useTranslation()
  return (
    <div className='block maintenance-block'>
      <div className="block-head">
        <h2 className='block-title'>{t('system.maintenance.title')}</h2>
      </div>
      <div className='block-content'>
      <p>{t('system.placeholder')}</p>
      </div>
    </div>
  )
}

const ParametresBlock = () => {
  const {t} = useTranslation()
  return (
    <div className='block parametre-block'>
    <div className="block-head">
      <h2 className='block-title'>{t('system.parametre.title')}</h2>
    </div>
      <div className='block-content'>
      <p>{t('system.placeholder')}</p>
      </div>
    </div>
  )
}

export default function SystemPage() {
    const {t} = useTranslation()

    document.title = `${t('reports.documentTitle')} - ${
        Constants.DOCUMENT_TITLE_BACKOFFICE
    }`

    return (
        <HtmlStructure menuName='system' sectionClassName='system'>
          <div className='system-container'>
            <div className='blocks'>
              <MaintenanceBlock />
              <InformationBlock />
              <ParametresBlock />
            </div>
          </div>
        </HtmlStructure>
    )
}
