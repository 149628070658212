import {Component, useState} from 'react'
import {Api} from 'scg.common-library'
import {TableSkeleton} from '../../../services/LoadingHelper'
import ReactSwitch from 'react-switch'
import Helper from '../../../services/Helper'
import {withTranslation} from 'react-i18next'

import './typeList.css'
import ScgDataTable from '../../general/ScgDataTable'

const TypeList = withTranslation()(
    class extends Component {
        static defaultProps = {}

        constructor(props) {
            super(props)

            this.state = {
                loading: true,
                questionTypes: [],
                totalRows: 0,
                perPage: Helper.getTablePerPageNumber()
            }

            this.handlePerRowsChange = this.handlePerRowsChange.bind(this)
            this.handlePageChange = this.handlePageChange.bind(this)
        }

        componentDidMount() {
            (async function() {
                Api.questionType.getQuestionTypeCount().then((response) => {
                    this.setState({totalRows: response.count})
                })
                getQuestionTypeLines.bind(this)(1)
            }).bind(this)()
        }

        handlePerRowsChange(newPerPage, page) {
            (async function() {
                this.setState({perPage: newPerPage, loading: true}, () => {
                    Helper.setTablePerPageNumber(newPerPage)
                    getQuestionTypeLines.bind(this)(page)
                })
            }).bind(this)()
        }

        handlePageChange(page) {
            (async function() {
                this.setState({loading: true}, () => {
                    getQuestionTypeLines.bind(this)(page)
                })
            }).bind(this)()
        }

        render() {
            const {t} = this.props

            const columns = [
                {
                    name: t('tableColumnNames.id'),
                    selector: (row) => row.id,
                    sortable: true,
                    width: '5vw'
                },
                {
                    name: t('tableColumnNames.name'),
                    selector: (row) => row.name,
                    sortable: true,
                    width: '50vw',
                    cell: (row) => formatText(row.name)
                },
                {
                    name: t('tableColumnNames.shortName'),
                    selector: (row) => row.shortName,
                    sortable: true,
                    width: '25vw',
                    cell: (row) => formatText(row.shortName)
                },
                {
                    name: t('tableColumnNames.active'),
                    selector: (row) => row.active,
                    width: '5vw'
                }
            ]

            return (
                <article className='questionTypes'>
                    <h3>{t('quiz.pages.types.title')}</h3>
                    <section className='questionTypes-table card'>
                        {this.state.loading
                            ? <TableSkeleton linesCount={22} size='large' />
                            : <div className='questionTypes-table-content'>
                                <ScgDataTable
                                    columns={columns}
                                    data={this.state.questionTypes}
                                    paginationTotalRows={this.state.totalRows}
                                    paginationPerPage={this.state.perPage}
                                    onChangeRowsPerPage={this.handlePerRowsChange}
                                    onChangePage={this.handlePageChange}
                                    className='typesTable'
                                />
                            </div>}
                    </section>
                </article>
            )
        }
    }
)

/* ================================== GLOBAL FUNCTIONS ================================== */

/**
 * Call questionTypes Api and update table state with the result of the api call
 *
 * @param page {int}
 * @param filters {object|null}
 * @param callback {Function|null}
 * @return {Promise<void>}
 */
async function getQuestionTypeLines(page = 1, filters = null, callback = null) {
    Helper.getDataTableLines.bind(this)(
        Api.questionType.getQuestionTypes,
        {'order[id]': 'asc', per_page: this.state.perPage},
        'questionTypes',
        FormatRow,
        page,
        filters,
        callback
    )
}

/**
 * Create a jsx object that will be interpreted by the DataTable
 *
 * @param questionType
 * @return {Promise<{name: JSX.Element, active: JSX.Element, id, shortName: JSX.Element}>}
 */
async function FormatRow(questionType) {
    return {
        id: questionType.id,
        name: questionType.name,
        shortName: questionType.shortName,
        active: <ActiveButton questionType={questionType} />
    }
}

/**
 * Return a switch component that update the questionType active state (questionType object passed in props)
 *
 * @param props {object}
 * @return {JSX.Element}
 */
function ActiveButton(props) {
    const [active, setActive] = useState(props.questionType.active)
    const handleActiveSwitchChange = (activeState) => {
        Api.questionType.updateQuestionType(props.questionType.id, {active: activeState}).then(() => {
            setActive(activeState)
        })
    }

    return (
        <ReactSwitch
            onChange={handleActiveSwitchChange}
            checked={active}
            className='questionTypes-active-switch'
            id={`questionType-${props.questionType.id}`}
            onColor='#99e7fc'
            offColor='#fc9999'
        />
    )
}

/**
 * Format a text to an html div used for dataTable display
 *
 * @param text {string}
 * @return {JSX.Element}
 */
function formatText(text) {
    return (
        <div className='text-display' title={text}>
            <span>{text}</span>
        </div>
    )
}

export default TypeList
