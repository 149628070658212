import {Component} from 'react'
import {Api, Constants} from 'scg.common-library'
import HtmlStructure from '../general/HtmlStructure'
import {TableWithFiltersSkeleton} from '../../services/LoadingHelper'
import moment from 'moment'
import Helper from '../../services/Helper'
import ActiveSwitch from '../general/form/ActiveSwitch'
import {InputField} from '../general/form/Input'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {SelectField} from '../general/form/Select'
import Modal from '../general/form/Modal'
import SocietyForm from './SocietyForm'
import {useTranslation, withTranslation} from 'react-i18next'
import i18next from 'i18next'

import './societies.css'
import ScgDataTable from '../general/ScgDataTable'

export default function Societies() {
    const {t} = useTranslation()

    document.title = `${t('societies.documentTitle')} - ${Constants.DOCUMENT_TITLE_BACKOFFICE
    }`

    return (
        <HtmlStructure menuName='society' sectionClassName='societies'>
            <SocietyList />
        </HtmlStructure>
    )
}

const SocietyList = withTranslation()(
    class extends Component {
        static defaultProps = {}

        constructor(props) {
            super(props)

            this.state = {
                loading: true,
                societies: [],
                totalRows: 0,
                perPage: Helper.getTablePerPageNumber(),
                showSocietyForm: false,
                currentSociety: null
            }

            this.handlePerRowsChange = this.handlePerRowsChange.bind(this)
            this.handlePageChange = this.handlePageChange.bind(this)
            this.handleFilter = this.handleFilter.bind(this)
            this.showSocietyForm = this.showSocietyForm.bind(this)
            this.editCurrentSociety = this.editCurrentSociety.bind(this)
            this.updateModifyedLine = this.updateModifyedLine.bind(this)
        }

        componentDidMount() {
            Api.society.getSocietiesCount().then((response) => {
                const resultObject = Helper.isValidResponse(response)

                if (resultObject) {
                    this.setState({totalRows: resultObject.count})
                }
            })
            getSocietyLines.bind(this)(1)
        }

        async updateModifyedLine(prevSociety, updatedSociety) {
            getSocietyLines.bind(this)(1)

            let indexOfSelectedLine = this.state.societies.findIndex(
                (society) => society.id === prevSociety.id
            )
            if (indexOfSelectedLine > -1) {
                let newArraySocieties = [].concat(this.state.societies)
                const formated = async () => await FormatRow(updatedSociety, this)
                newArraySocieties[indexOfSelectedLine] = await formated()
                this.setState({societies: newArraySocieties})
            }
        }

        showSocietyForm() {
            this.setState({showSocietyForm: true})
        }

        editCurrentSociety(society) {
            this.setState({currentSociety: society})
            this.showSocietyForm()
        }

        getFilters() {
            console.debug('getFilters not initialized !')
            return []
        }

        handlePerRowsChange(newPerPage, page) {
            this.setState({perPage: newPerPage, loading: true}, () => {
                Helper.setTablePerPageNumber(newPerPage)
                getSocietyLines.bind(this)(page, this.getFilters())
            })
        }

        handlePageChange(page) {
            this.setState({loading: true}, () => {
                getSocietyLines.bind(this)(page, this.getFilters())
            })
        }

        handleFilter(filterFunctionFromChild, updateButtonStateFunction) {
            this.setState({loading: true})
            updateButtonStateFunction(true)

            this.getFilters = filterFunctionFromChild
            const filters = this.getFilters()

            Api.society.getSocietiesCount(Helper.getFiltersUrlParams(filters)).then((response) => {
                const resultObject = Helper.isValidResponse(response)
                if (resultObject) {
                    this.setState({totalRows: resultObject.count})
                }
            })

            getSocietyLines.bind(this)(1, filters, updateButtonStateFunction)
        }

        render() {
            const {t} = this.props

            const columns = [
                {
                    name: t('tableColumnNames.id'),
                    selector: (row) => row.id,
                    sortable: true,
                    center: true,
                    width: '3vw'
                },
                {
                    name: t('tableColumnNames.name'),
                    selector: (row) => row.title.titleName.toLowerCase(),
                    sortable: true,
                    width: '38vw',
                    cell: (row) => row.title.span
                },
                {
                    name: t('tableColumnNames.users'),
                    selector: (row) => row.currentAccounts,
                    sortable: true,
                    center: true,
                    width: '5vw'
                },
                {
                    name: t('tableColumnNames.maxUsers'),
                    selector: (row) => row.maxAccounts.maxAccountValue,
                    sortable: true,
                    center: true,
                    width: '7vw',
                    cell: (row) => row.maxAccounts.span

                },
                {
                    name: t('tableColumnNames.subscriptionDate'),
                    selector: (row) => new Date(row.subscriptionDate.dateValue),
                    center: true,
                    sortable: true,
                    width: '10vw',
                    cell: (row) => row.subscriptionDate.span
                },
                {
                    name: t('tableColumnNames.subscriber'),
                    selector: (row) => row.subscribed.subscribedValue,
                    sortable: true,
                    center: true,
                    width: '7vw',
                    cell: (row) => row.subscribed.span
                },
                {
                    name: t('tableColumnNames.subscriptionEndDate'),
                    selector: (row) => new Date(row.subscriptionExpirationDate.dateValue),
                    center: true,
                    sortable: true,
                    width: '10vw',
                    cell: (row) => row.subscriptionExpirationDate.span
                },
                {
                    name: t('tableColumnNames.active'),
                    selector: (row) => row.active.activeValue,
                    center: true,
                    sortable: true,
                    width: '5vw',
                    cell: (row) => row.active.span
                }
            ]

            return (
                <article className='societies'>
                    <section className='bo-data-title'>
                        <h3>{t('societies.title')}</h3>
                        <button
                            className={`btn default addButton ${this.state.loading ? 'disabled' : ''
                            }`}
                            title={t('societies.addCompanyButton.title')}
                            onClick={() => {
                                this.setState({currentSociety: null})
                                this.showSocietyForm()
                            }}
                        >
                            <FontAwesomeIcon icon='fas fa-add' />{' '}
                            {t('societies.addCompanyButton.name')}
                        </button>
                    </section>

                    <Modal
                        isShowing={this.state.showSocietyForm}
                        title={t('societies.addModal.title')}
                        hide={() => this.setState({showSocietyForm: false})}
                    >
                        <SocietyForm
                            currentSociety={this.state.currentSociety}
                            abortingCallback={() => this.setState({showSocietyForm: false})}
                            successCallback={() => this.handlePageChange(1)}
                            validationButtonLabel={
                                this.state.currentSociety !== null
                                    ? t('societies.addModal.buttons.ok.save')
                                    : t('societies.addModal.buttons.ok.create')
                            }
                        />
                    </Modal>

                    <section className='bo-data-table card'>
                        {this.state.loading && (
                            <TableWithFiltersSkeleton linesCount={20} filtersCount={3} linesSize='large' />
                        )}
                        <>
                            <SocietyFilters onFilter={this.handleFilter} />
                            <div className='bo-data-table-content'>
                                <ScgDataTable
                                    columns={columns}
                                    data={this.state.societies}
                                    paginationTotalRows={this.state.totalRows}
                                    paginationPerPage={this.state.perPage}
                                    onChangeRowsPerPage={this.handlePerRowsChange}
                                    onChangePage={this.handlePageChange}
                                    className='societiesTable'
                                />
                            </div>
                        </>

                    </section>
                </article>
            )
        }
    }
)

const SocietyFilters = withTranslation()(
    class extends Component {
        static defaultProps = {}

        constructor(props) {
            super(props)
            this.state = {
                societyFilterText: '',
                selectedSubscribedOptions: null,
                selectedActiveOptions: null,
                hasFilters: false,
                loading: false
            }
            this.subscribedOptions = [
                {
                    label: props.t('societies.filters.status.label'),
                    icon: 'fa-list-check',
                    options: [
                        {
                            value: true,
                            label: props.t('societies.filters.status.options.first')
                        },
                        {
                            value: false,
                            label: props.t('societies.filters.status.options.second')
                        }
                    ]
                }
            ]
            this.activeOptions = [
                {
                    label: props.t('societies.filters.state.label'),
                    icon: 'fa-table-list',
                    options: [
                        {
                            value: true,
                            label: props.t('societies.filters.state.options.first')
                        },
                        {
                            value: false,
                            label: props.t('societies.filters.state.options.second')
                        }
                    ]
                }
            ]

            this.handleFilterSubmit = this.handleFilterSubmit.bind(this)
            this.handleClearFilter = this.handleClearFilter.bind(this)
            this.handleSubscribedChange = this.handleSubscribedChange.bind(this)
            this.handleActiveChange = this.handleActiveChange.bind(this)
            this.updateButtonState = this.updateButtonState.bind(this)
            this.getFilters = this.getFilters.bind(this)
        }

        getFilters() {
            let filters = {}

            // retrieves typed text
            if (this.state.societyFilterText !== '') {
                filters.filterText = this.state.societyFilterText
            }

            // retrieves selected subscribtion state
            if (
                this.state.selectedSubscribedOptions !== null &&
                Object.keys(this.state.selectedSubscribedOptions).length !== 0
            ) {
                filters.subscribed = this.state.selectedSubscribedOptions.value
            }

            // retrieves selected active state
            if (
                this.state.selectedActiveOptions !== null &&
                Object.keys(this.state.selectedActiveOptions).length !== 0
            ) {
                filters.active = this.state.selectedActiveOptions.value
            }

            this.setState(() => {
                return {hasFilters: Object.keys(filters).length !== 0}
            })

            return filters
        }

        handleSubscribedChange(selectedOptions) {
            this.setState({
                selectedSubscribedOptions: selectedOptions
            })
        }

        handleActiveChange(selectedOptions) {
            this.setState({
                selectedActiveOptions: selectedOptions
            })
        }

        handleFilterSubmit() {
            this.props.onFilter(this.getFilters, this.updateButtonState)
        }

        handleClearFilter() {
            this.setState(
                {
                    societyFilterText: '',
                    selectedSubscribedOptions: [],
                    selectedActiveOptions: [],
                    hasFilters: false
                },
                () => {
                    this.handleFilterSubmit()
                }
            )
        }

        updateButtonState(isLoading = false) {
            this.setState({loading: isLoading})
        }

        render() {
            const {t} = this.props

            return (
                <div className='bo-data-filters'>
                    <InputField
                        className='societies-text'
                        type='text'
                        name='societyFilterText'
                        value={this.state.societyFilterText}
                        title={t('searchInput.title')}
                        context={this}
                    >
                        {t('searchInput.label')}
                    </InputField>
                    <SelectField
                        className='formField'
                        classNamePrefix='reactSelect-custom'
                        options={this.subscribedOptions}
                        value={this.state.selectedSubscribedOptions}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder={t('societies.filters.status.label')}
                        onChange={this.handleSubscribedChange}
                        context={this}
                    />
                    <SelectField
                        className='formField'
                        classNamePrefix='reactSelect-custom'
                        options={this.activeOptions}
                        value={this.state.selectedActiveOptions}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        placeholder={t('societies.filters.state.label')}
                        onChange={this.handleActiveChange}
                        context={this}
                    />
                    <div>
                        <button
                            type='button'
                            className='btn default filterButton'
                            title={t('filterButtonTitles.results')}
                            disabled={this.state.loading}
                            onClick={this.handleFilterSubmit}
                        >
                            <FontAwesomeIcon icon='fas fa-filter' />
                        </button>
                        {this.state.hasFilters && (
                            <button
                                type='button'
                                className='btn warning resetButton'
                                title={t('filterButtonTitles.clear')}
                                onClick={this.handleClearFilter}
                            >
                                <FontAwesomeIcon icon='fas fa-filter-circle-xmark' />
                            </button>
                        )}
                    </div>
                </div>
            )
        }
    }
)

/* ================================== GLOBAL FUNCTIONS ================================== */
/**
 * Call questions Api and update table state with the result of the api call
 *
 * @param page {int}
 * @param filters {object|null}
 * @param callback {Function|null}
 * @return {Promise<void>}
 */
async function getSocietyLines(page = 1, filters = null, callback = null) {
    Helper.getDataTableLines.bind(this)(
        Api.society.getSocieties,
        {'order[id]': 'asc', per_page: this.state.perPage},
        'societies',
        FormatRow,
        page,
        filters,
        callback
    )
}

/**
 * Create a jsx object that will be interpreted by the DataTable
 *
 * @param society {object}
 * @param obj
 */
async function FormatRow(society, obj = this) {
    let maxAccounts, title, active

    if (society.maxAccounts === -1) {
        maxAccounts = '∞'
        title = Helper.FormatText(society.name)
        active = Helper.FormatText('-')
    } else {
        maxAccounts = society.maxAccounts
        title = (
            <a
                href='/'
                onClick={(e) => {
                    e.preventDefault()
                    obj.editCurrentSociety(society)
                }}
                className='bo-data-table_clickable'
            >
                {Helper.FormatLink(society.name, 'bo-data-table_clickable', null)}
            </a>
        )

        active = (
            <ActiveSwitch
                objectActive={society.active}
                objectId={society.id}
                apiFunction={Api.society.updateSociety}
                idPrefix='society'
                className='societies-active-switch'
            />
        )
    }

    return {
        id: society.id,
        title: {titleName: society.name, span: title},
        currentAccounts: society.users.length, //.length,
        maxAccounts: {maxAccountValue: society.maxAccounts, span: Helper.FormatText(maxAccounts)},
        subscriptionDate: {
            dateValue: society.subscriptionDate, span: Helper.FormatText(
                society.subscriptionDate !== null
                    ? moment(society.subscriptionDate).format('DD/MM/YYYY HH:mm')
                    : ''
            )
        },
        subscribed: {
            subscribedValue: society.subscribed
                ? i18next.t('societies.subscribed.yes')
                : i18next.t('societies.subscribed.no'),
            span: Helper.FormatBadge(
                society.subscribed
                    ? i18next.t('societies.subscribed.yes')
                    : i18next.t('societies.subscribed.no'),
                society.subscribed
            )
        },
        subscriptionExpirationDate: {
            dateValue: society.subscriptionExpirationDate, span: Helper.FormatText(
                society.subscriptionExpirationDate !== null
                    ? moment(society.subscriptionExpirationDate).format('DD/MM/YYYY HH:mm')
                    : ''
            )
        },
        active: {activeValue: society.active, span: active}
    }
}
