import DataTable from 'react-data-table-component'
import {DEFAULT_ROWS_PER_PAGE} from '../../services/Constants'
import {useTranslation} from 'react-i18next'

export default function ScgDataTable(props) {
    const {t} = useTranslation()

    return <DataTable
        dense={true}
        columns={props.columns}
        data={props.data}
        fixedHeader
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={props.totalRows}
        paginationPerPage={props.perPage}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        onChangePage={props.onChangePage}
        paginationComponentOptions={{
            rowsPerPageText: t('general.utils.rows_per_page'),
            selectAllRowsItem: true,
            selectAllRowsItemText: t('general.utils.rows_per_page_ALL')
        }}
        paginationRowsPerPageOptions={DEFAULT_ROWS_PER_PAGE}
        responsive
        className={props.className}
        subHeaderAlign='center'
        subHeaderWrap
    />
}