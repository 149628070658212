import { Stack, Skeleton } from '@mui/material'

export default function SocietyDetailSkeleton(){
    return (
        <Stack spacing={1}>
            <div>
                <Skeleton animation='wave' variant='rounded' width='250px' height='27px' style={{ marginBottom: '5px' }} />
                <Skeleton animation='wave' variant='rounded' width='100%' height='27px' />
            </div>
            <div>
                <Skeleton animation='wave' variant='rounded' width='250px' height='27px' style={{ marginBottom: '5px' }} />
                <Skeleton animation='wave' variant='rounded' width='100%' height='27px' />
            </div>
            <div>
                <Skeleton animation='wave' variant='rounded' width='250px' height='27px'style={{ marginBottom: '5px' }} />
                <Skeleton animation='wave' variant='rounded' width='100%' height='27px' />
            </div>
        </Stack>
    )
}
