import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import QuestionForm from "./question/QuestionForm"

const QuizzModal = ({ hide, quizz, isShowing, quizzType, handleActions }) => {
    
    // states
    const [questionType, setQuestionType] = useState(null)
    const [questionId, setQuestionId] = useState(null)
    // hooks
    const { t } = useTranslation()

    useEffect( () => {

        if( quizz ){
            if( typeof(quizz) === 'object' ){
                setQuestionId(quizz.id)
                setQuestionType(quizz.questionType.id)
            }else{
                setQuestionId(quizz)
                setQuestionType( quizzType )
            }
        }else{
            if(quizzType){
                setQuestionId(null)
                setQuestionType( quizzType )
            }else{
                setQuestionId(null)
                setQuestionType(null)
            }
        }

    }, [ quizz, quizzType ] )

    const handleDelete = (question) => {
        if( typeof(handleActions) === 'function' ){
            handleActions('delete', question)
        }
    }

    const handleSave = (question) => {
        if( typeof(handleActions) === 'function' ){
            handleActions('save', question)
        }
    }

    return isShowing && questionType
        ? createPortal(
            <>
                <section className="modal-wrapper modal-quizz">
                    <div className="modal-overlay" />
                    <div className="modal">
                        <div className="modal-header">
                            <h4>{ questionId ? t('quiz.forms.updateTitle') : t('quiz.forms.newTitle') }</h4>
                            <button
                                type="button"
                                className="btn alert modal-close-button"
                                title={t('general.form.modal.closeButtonTitle')}
                                onClick={hide}
                            >
                            <FontAwesomeIcon icon="fas fa-circle-xmark" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <QuestionForm.QuestionForm 
                                questionId={questionId}
                                questionType={questionType} 
                                handleDelete={handleDelete}
                                handleSave={handleSave}
                            />
                        </div>
                    </div>
                </section>
            </>,
            document.body
        )
        : null;
};

QuizzModal.defaultProps = {
  quizz : null,
  quizzType : null
}

QuizzModal.propTypes = {
    hide : PropTypes.func.isRequired,
    isShowing : PropTypes.bool.isRequired,
    quizz : PropTypes.any,
    quizzType : PropTypes.number.isRequired,
    handleActions: PropTypes.func.isRequired
}

export default QuizzModal;
