import {useEffect, useState} from 'react'
import HtmlStructure from '../general/HtmlStructure'
import {Api, Constants, Session} from 'scg.common-library'
import {TableSkeleton} from '../../services/LoadingHelper'
import {useObjectState} from '../../hooks/customHooks'
import Helper from '../../services/Helper'
import {EMAIL_SUPPORT} from '../../services/Constants'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from '@mui/material'
import _ from 'lodash'
import SocietyDetailSkeleton from './SocietyDetailSkeleton'
import {
    Circle as CircleIcon,
    CheckCircle as CheckCircleIcon,
    QueryStats
} from '@mui/icons-material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import ScgDataTable from '../general/ScgDataTable'
import StatisticsModal from './StatisticsModal'
import styled from '@emotion/styled'

const QueryStatsIcon = styled(QueryStats)`
  cursor: pointer
`
export default function SocietyDetails() {
    const {t} = useTranslation()
    document.title = `${t('societyDetails.documentTitle')} - ${Constants.DOCUMENT_TITLE_BACKOFFICE}`

    const user = Session.getSessionUser()

    return <HtmlStructure
        menuName='society_details'
        sectionClassName='society_details'
    >
        <Details id={user?.society?.id} />
    </HtmlStructure>
}

function UserList({onViewStatistics}) {
    const {t} = useTranslation()
    const [users, setUers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [perPage, setPerPage] = useState(0)
    const [totalRows, setTotalRows] = useState(40)

    const columns = [
        {
            name: t('tableColumnNames.id'),
            selector: row => row.id,
            center: true,
            sortable: true,
            width: '3vw'
        },
        {
            name: t('tableColumnNames.name'),
            selector: row => row.name,
            format: row => Helper.FormatText(row.name + ' ' + row.surname),
            sortable: true,
            width: '9vw'
        },
        {
            name: t('tableColumnNames.level'),
            selector: row => row.level,
            format: row => Helper.FormatLevel(row.level, false),
            sortable: true,
            width: '3vw'
        },
        {
            name: t('tableColumnNames.experience'),
            selector: row => row.currentExperience,
            format: row => {
                const percent = _.ceil(
                    (parseInt(row.currentExperience) * 100) / parseInt(row.level.maxExperience))
                return `${row.currentExperience} / ${row.level.maxExperience} (${percent}%)`
            },
            sortable: true,
            width: '6vw'
        },
        {
            name: t('tableColumnNames.points'),
            selector: row => row.points,
            format: row => `${parseInt(row.points)} pts`,
            sortable: true,
            width: '5vw'
        },
        {
            name: t('tableColumnNames.nickname'),
            selector: row => row.pseudo,
            format: row => Helper.FormatText(`${row.pseudo} #${row.tag}`),
            sortable: true,
            width: '9vw'
        },
        {
            name: t('tableColumnNames.email'),
            selector: row => row.email,
            format: row => Helper.FormatText(row.email),
            width: '12vw'
        },
        {
            name: t('tableColumnNames.lastConnexion'),
            selector: row => row.lastConnexion,
            format: row => {
                if (!row.lastConnexion) {
                    return '-'
                }
                return Helper.FormatText(moment(row.lastConnexion).format('DD/MM/YYYY HH:mm'))
            },
            width: '8vw'
        },
        {
            name: t('tableColumnNames.progression'),
            selector: row => row.progression,
            format: row => Helper.FormatProgression(row.progression),
            width: '5vw'
        },
        {
            name: t('tableColumnNames.bestCategory'),
            selector: row => row.bestCategory,
            format: row => {
                let progress = ''
                if (!row.bestCategory.category) {
                    return '-'
                }
                if (row.bestCategory.percent) {
                    progress = `(${_.ceil(row.bestCategory.percent * 100)} %)`
                }
                return `${row.bestCategory.category?.name} ${progress}`
            },
            width: '10vw'
        },
        {
            name: t('tableColumnNames.worstCategory'),
            selector: row => row.worstCategory,
            format: row => {
                let progress = ''
                if (!row.worstCategory.category) {
                    return '-'
                }
                if (row.worstCategory.percent) {
                    progress = `(${_.ceil(row.worstCategory.percent * 100)} %)`
                }
                return `${row.worstCategory.category?.name} ${progress}`
            },
            width: '10vw'
        },
        {
            name: t('tableColumnNames.statistiques'),
            center: true,
            width: '4vw',
            selector: row => <QueryStatsIcon fontSize='large' onClick={() => onViewStatistics(row)} />
        }
    ]

    useEffect(() => {
        getUserLines(1)
        setPerPage(Helper.getTablePerPageNumber())
    }, [])

    const getUserLines = (page) => {
        Api.society.getUsersSociety(page).then(response => {
            const data = Helper.isValidResponse(response)
            setUers(data)
            setTotalRows(data.count)
        }).finally(() => setIsLoading(false))
    }

    if (isLoading) {
        return <TableSkeleton linesCount={20} size='large' />
    }

    return (
        <div className='bo-data-table-content' style={{}}>
            <ScgDataTable
                columns={columns}
                data={users}
                paginationTotalRows={totalRows}
                paginationPerPage={perPage}
                // onChangeRowsPerPage={this.handlePerRowsChange}
                // onChangePage={this.handlePageChange}
                className='societeUsersTable'
            />
        </div>
    )

}

function Details({id}) {
    const {t} = useTranslation()

    const [user, setUser] = useState(null)
    const [showStatistics, setShowStatistics] = useState(false)

    const [state, setState] = useObjectState({
        loading: true,
        modalOpen: false,
        society: null,
        numberUser: 0
    })

    useEffect(() => {
        Promise.all([
            Api.society.getSociety(id),
            Api.society.getUsersCountSociety()
        ]).then(([societyResponse, usersCountSocietyResponse]) => {
            if (societyResponse.status === Constants.HTTP_OK && usersCountSocietyResponse.status ===
                Constants.HTTP_OK) {
                setState({
                    loading: false,
                    society: societyResponse.data,
                    numberUser: usersCountSocietyResponse.data.count
                })
            }
        })
        // eslint-disable-next-line
    }, [])

    const _handleModalOpen = () => {
        setState({modalOpen: true})
    }

    const _handleModalClose = () => {
        setState({modalOpen: false})
    }

    const _handleStatisticsView = user => {
        if (user) {
            setUser(user)
            setShowStatistics(true)
        }
    }

    const renderEtatAbonement = () => {
        const now = moment(new Date())
        const expiration = moment(state.society?.subscriptionExpirationDate)
        const diff = expiration.diff(now, 'days')
        if (!state.society?.subscribed) {
            return <>
                <CircleIcon className='subscription-expired' />
                {t('societyDetails.subscription.status.expire')}
            </>
        }
        return <>
            <CheckCircleIcon className='subscription-active' />
            {t('societyDetails.subscription.status.active')} - {diff} {t(
            'societyDetails.subscription.joursRestant')}
        </>
    }

    return <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={7} className='help'>
            <div className='card'>
                <span dangerouslySetInnerHTML={{__html: t('societyDetails.info.firstRow')}} />
                <span dangerouslySetInnerHTML={{__html: t('societyDetails.info.secondRow')}} />
                <span dangerouslySetInnerHTML={{__html: t('societyDetails.info.thirdRow')}} />
                <Button variant='contained' onClick={_handleModalOpen}>
                    {t('societyDetails.subscriptionButton')}
                </Button>
                <Dialog open={state.modalOpen} onClose={_handleModalClose}>
                    <DialogTitle>{t('societyDetails.modal.title')}</DialogTitle>
                    <DialogContent>
                        {t('societyDetails.modal.text')}:{' '}
                        <a
                            href={`mailto:${EMAIL_SUPPORT}?subject=${t('societyDetails.email.subject')}`}
                            children={EMAIL_SUPPORT}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        </Grid>
        <Grid item xs={5} className='subscription'>
            <div className='card'>
                {
                    state.loading ?
                        <SocietyDetailSkeleton />
                        :
                        <>
                            <Typography variant='overline' gutterBottom>
                                {t('societyDetails.subscription.title')}
                            </Typography>
                            <Typography variant='h6' gutterBottom>
                                {renderEtatAbonement()}
                            </Typography>
                            <Typography variant='overline' gutterBottom>
                                {t('societyDetails.endDateTitle')}
                            </Typography>
                            <Typography variant='h6' gutterBottom>
                                {moment(state.society?.subscriptionExpirationDate).format(
                                    'DD/MM/YYYY'
                                ) ?? '?'}
                            </Typography>
                            <Typography variant='overline' gutterBottom>
                                {t('societyDetails.usersTitle')}
                            </Typography>
                            <Typography variant='h6' gutterBottom>
                                {state.numberUser} / {state.society?.maxAccounts}
                            </Typography>
                        </>
                }
            </div>
        </Grid>
        <Grid item xs={12} className='users-state'>
            <div className='card'>
                <UserList onViewStatistics={_handleStatisticsView} />
                <StatisticsModal user={user} open={showStatistics} onClose={() => setShowStatistics(false)} />
            </div>
        </Grid>
    </Grid>
}
