/* ------------------------ QUESTIONS TYPES -------------------------- */

export const QUESTION_TYPES = {
    MCQ: 'mcq',
    Gaps: 'gaps',
    TrueFalse: 'trueFalse'
}

/* ------------------------------ MISCELLANEOUS -------------------------------- */

export const DEFAULT_ROWS_PER_PAGE = [10, 20, 30, 50, 100]
export const MAX_GAPS_ANSWERS = 8
export const DEFAULT_PER_PAGE_NUMBER = 15
export const COOKIE_PER_PAGE_NUMBER = '_itemsPerpage'
export const COOKIE_NOTIFICATION = '_lsn'
export const COOKIE_NOTIFICATION_TOOLTIP = '_lsn_info'
export const SESSION_EXPIRATION_DAYS = 30

export const ACTIVE = 'active'
export const INACTIVE = 'inactive'

/* ============================ EXPORTS ============================ */

export const EMAIL_SUPPORT = 'support@nextaura.com'
